enum RoutePaths {
  HOME = '/',
  PERSONAL = '/personal',
  GIFT = '/gift',
  DIGITAL_GIFT = '/gift/digital',
  LETTER_GIFT = '/gift/letter',
  THANK_YOU = '/thank-you',
  PAYMENT_METHOD = '/payment-method',
  PAYMENT_SUCCESS = '/payment-success',
  PAYMENT_CANCEL = '/payment-cancel',
  PAYMENT_FAILURE = '/payment-failure',
  RECEIPT_FORM = '/receipt-form',
  GIFT_RECEIPT_FORM = '/gift-receipt-form',
}

export default RoutePaths
