import React, { useMemo, useReducer, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PersonalInfo from '../Components/Personal'
import NumberOfPeople from '../Components/Buy/Gift/NumberOfPeople'
import PaymentMethod from '../Components/Payment'
import GiftForm from '../Components/Buy/Gift/Form'
import EnvelopeGiftForm from '../Components/Buy/Gift/EnvelopeForm'
import RoutePaths from './RoutePaths'
import PaymentRequestContext, { PaymentRequestReducerType } from '../Contexts/PaymentRequestContext'
import reducer, { initialState } from '../Contexts/PaymentRequestContext/reducer'
import ThankYou from '../Components/ThankYou'
import HomePage from '../Components/HomePage'
import PaymentSuccess from '../Components/Payment/State/PaymentSuccess'
import PaymentFailure from '../Components/Payment/State/PaymentFailure'
import ReceiptForm from '../Components/ThankYou/ReceiptForm'
import GiftReceiptForm from '../Components/ThankYou/GiftReceiptForm'
import { useTranslation } from 'react-i18next'
import { renderLang } from '../utils'
import TagManager from 'react-gtm-module'
import { TAG } from '../constants/env'

const Routes: React.FunctionComponent = () => {
  const [state, dispatch] = useReducer<PaymentRequestReducerType>(reducer, initialState)
  const PaymentRequestContextValue = useMemo(() => ({ state, dispatch }), [state])
  const [, i18n] = useTranslation()
  const { ready } = useTranslation()
  const supportedLanguages = ['fi', 'en', 'sv']

  useEffect(() => {
    const body = document.body
    const noScript = document.createElement('noscript')
    const iframeSrc = `<iframe
        src="https://www.googletagmanager.com/ns.html?id=${TAG}"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>`
    noScript.append(iframeSrc)
    body.appendChild(noScript)

    const tagManagerArgs = {
      gtmId: TAG,
      dataLayerName: 'dataLayer',
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  const render = (Component: any, props: any) => {
    const lang = props.match.params.lang
    if (!lang) {
      return <Redirect to={`${props.match.path}/${renderLang()}`} />
    } else {
      if (supportedLanguages.includes(lang)) {
        if (lang !== localStorage.getItem('i18nextLng')) {
          localStorage.setItem('i18nextLng', lang)
          i18n.changeLanguage(lang)
        }
        return <Component />
      } else {
        return <Redirect to={`/${renderLang()}`} />
      }
    }
  }

  return (
    <PaymentRequestContext.Provider value={PaymentRequestContextValue}>
      {ready && (
        <Switch>
          <Route exact path="/">
            <Redirect to={`${RoutePaths.HOME}${renderLang()}`} />
          </Route>
          <Route
            exact
            path={[RoutePaths.PERSONAL, `${RoutePaths.PERSONAL}/:lang`]}
            render={(props: any) => render(PersonalInfo, props)}
          />
          <Route
            exact
            path={[RoutePaths.DIGITAL_GIFT, `${RoutePaths.DIGITAL_GIFT}/:lang`]}
            render={(props: any) => render(GiftForm, props)}
          />
          <Route
            exact
            path={[RoutePaths.LETTER_GIFT, `${RoutePaths.LETTER_GIFT}/:lang`]}
            render={(props: any) => render(EnvelopeGiftForm, props)}
          />
          <Route
            exact
            path={[RoutePaths.GIFT, `${RoutePaths.GIFT}/:lang`]}
            render={(props: any) => render(NumberOfPeople, props)}
          />
          <Route
            exact
            path={[RoutePaths.PAYMENT_METHOD, `${RoutePaths.PAYMENT_METHOD}/:lang`]}
            render={(props: any) => render(PaymentMethod, props)}
          />
          <Route
            exact
            path={[RoutePaths.THANK_YOU, `${RoutePaths.THANK_YOU}/:lang`]}
            render={(props: any) => render(ThankYou, props)}
          />
          <Route exact path={RoutePaths.PAYMENT_SUCCESS}>
            <PaymentSuccess />
          </Route>
          <Route exact path={RoutePaths.PAYMENT_FAILURE}>
            <PaymentFailure />
          </Route>
          <Route exact path={RoutePaths.PAYMENT_CANCEL}>
            <PaymentFailure />
          </Route>
          <Route
            exact
            path={[RoutePaths.RECEIPT_FORM, `${RoutePaths.RECEIPT_FORM}/:lang`]}
            render={(props: any) => render(ReceiptForm, props)}
          />
          <Route
            exact
            path={[RoutePaths.GIFT_RECEIPT_FORM, `${RoutePaths.GIFT_RECEIPT_FORM}/:lang`]}
            render={(props: any) => render(GiftReceiptForm, props)}
          />
          <Route exact path={RoutePaths.HOME + ':lang'} render={(props: any) => render(HomePage, props)} />
        </Switch>
      )}
    </PaymentRequestContext.Provider>
  )
}

export default Routes
