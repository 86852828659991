import React, { useEffect } from 'react'
import styled from 'styled-components'
import WelcomeSection from './WelcomeSection'
import { css } from '@emotion/core'
import ChooseShoppingTypeSection from './ChooseShoppingTypeSection'
import Quotes from './Quotes'
import CardForCompany from './CardForCompany'
import AppBanner from './AppBanner'
import PaymentMethodLogos from './PaymentMethodLogos'
// import StickyBanner from './StickyBanner'
import '../../assets/fonts/newHomePage/fonts.css'
import { getPrices } from '../../store/webshop/price/actions/getPrices'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { createClearPaymentErrorMessage } from '../../store/webshop/payment/update/actions/creators'
import { createClearSuccessErrorMessage } from '../../store/webshop/payment/success/actions/creators'
import { viewItemList, viewItem } from '../../utils/tagManagerReport'

const baseSectionCss = css`
  width: 100%;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const HomePage: React.FunctionComponent<Props> = ({
  getPrice,
  cardPrice,
  renewalAfterExpiry,
  giftPrice,
  renewalBeforeExpiry,
  paymentError,
  transactionPaymentError,
  createClearSuccessErrorMessage,
  createClearPaymentErrorMessage,
}) => {
  const [t] = useTranslation()
  useEffect(() => {
    getPrice()
  }, [getPrice])

  useEffect(() => {
    viewItemList(cardPrice, giftPrice)
    viewItem(cardPrice, renewalAfterExpiry, giftPrice)
  }, [cardPrice, renewalAfterExpiry, giftPrice])

  useEffect(() => {
    if (paymentError) {
      toast.error(t(paymentError))
      createClearSuccessErrorMessage()
    }
  }, [paymentError, t, createClearSuccessErrorMessage])

  useEffect(() => {
    if (transactionPaymentError) {
      toast.error(t(transactionPaymentError))
      createClearPaymentErrorMessage()
    }
  }, [transactionPaymentError, t, createClearPaymentErrorMessage])

  return (
    <Main>
      <WelcomeSection css={baseSectionCss} />
      <ChooseShoppingTypeSection
        css={baseSectionCss}
        cardPrice={cardPrice}
        giftPrice={giftPrice}
        renewalAfterExpiry={renewalAfterExpiry}
        renewalBeforeExpiry={renewalBeforeExpiry}
      />
      <Quotes css={baseSectionCss} />
      <CardForCompany css={baseSectionCss} />
      <AppBanner css={baseSectionCss} />
      <PaymentMethodLogos css={baseSectionCss} />
      {/* <StickyBanner /> */}
    </Main>
  )
}

interface Props extends MappedState, MappedDispatch {}
interface MappedState {
  cardPrice?: number
  giftPrice?: number
  renewalBeforeExpiry?: number
  renewalAfterExpiry?: number
  paymentError?: any
  transactionPaymentError?: string
}

interface MappedDispatch {
  getPrice: () => void
  createClearPaymentErrorMessage: () => void
  createClearSuccessErrorMessage: () => void
}
//TODO, remove /100 after BE refactor
const mapStateToProps = (state: RootState): MappedState => ({
  cardPrice: state.webshop.price.data && state.webshop.price.data.card / 100,
  giftPrice: state.webshop.price.data && state.webshop.price?.data.digital_gift / 100,
  renewalAfterExpiry: state.webshop.price.data && state.webshop.price?.data.renewal_after_expiry / 100,
  renewalBeforeExpiry: state.webshop.price.data && state.webshop.price.data.renewal_before_expiry / 100,
  paymentError: state.webshop.payment.success.error,
  transactionPaymentError: state.webshop.payment.update.transactionPaymentError,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getPrice: (): any => dispatch(getPrices()),
  createClearPaymentErrorMessage: (): any => dispatch(createClearPaymentErrorMessage()),
  createClearSuccessErrorMessage: (): any => dispatch(createClearSuccessErrorMessage()),
})
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)

const Main = styled.main`
  width: 100%;
  overflow: hidden;

  html {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    font-family: 'RightGrotesk-Wide', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #2c253d;
    text-rendering: optimizeLegibility;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: #2c253d;
  }

  a,
  html {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    font-family: 'RightGrotesk-Wide', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #2c253d;
    text-rendering: optimizeLegibility;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: #2c253d;
  }

  a,
  p,
  ul,
  li,
  ol,
  span {
    font-weight: 400;
    font-style: normal;
  }
  a strong,
  p strong,
  ul strong,
  li strong,
  ol strong,
  span strong {
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: normal;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    margin-top: 0;
    color: #ffdbd8;
  }

  blockquote {
    font-style: normal;
    position: relative;
    padding-top: 24px;
  }

  /*------------------------------------*\\
    PRINT
\\*------------------------------------*/
  @media print {
    * {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: ' (' attr(href) ')';
    }

    abbr[title]:after {
      content: ' (' attr(title) ')';
    }

    .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    @page {
      margin: 0.5cm;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }
  p,
  ul,
  li,
  ol,
  span {
    font-weight: 400;
    font-style: normal;
  }
  a strong,
  p strong,
  ul strong,
  li strong,
  ol strong,
  span strong {
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: normal;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    margin-top: 0;
    color: #ffdbd8;
  }

  blockquote {
    font-style: normal;
    position: relative;
    padding-top: 24px;
  }
`
