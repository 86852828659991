import React from 'react'
import styled from 'styled-components'
import { FaExclamation } from 'react-icons/fa'

const ErrorMessage: React.FunctionComponent = ({ children }) => {
  return (
    <Container>
      <FaExclamation className="icon" />
      <div className="children-wrapper">{children}</div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: #fff6f6;
  color: #9f3a38;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  padding-top: 1em;
  padding-right: 1.5em;
  display: flex;
  padding-bottom: 1em;
  padding-left: 1.5em;
  line-height: 1.4285em;
  border-color: #ffced4;
  border-width: 0.1em;
  border-style: solid;
  .children-wrapper {
    color: red;
    font-size: 12pt;
    font-weight: normal;
  }
  .icon {
    flex: 0 0 auto;
    width: auto;
    line-height: 1;
    vertical-align: middle;
    font-size: 3em;
    align-self: center;
    opacity: 0.8;
  }
`

export default ErrorMessage
