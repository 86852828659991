import React from 'react'
import ReactPhoneInput from 'react-phone-number-input'
import styled, { css } from 'styled-components'
import colors from '../../../constants/colors'

interface Props {
  value: string
  onChange: (value: string) => void
  placeholder: string
  errorMessage?: string
  id: string
}

const PhoneInput: React.FunctionComponent<Props> = ({ value, onChange, placeholder, errorMessage, id }) => {
  return (
    <Container hasError={!!errorMessage}>
      <ReactPhoneInput placeholder={placeholder} value={value} defaultCountry="FI" onChange={onChange} id={id} />
    </Container>
  )
}

interface StyledProps {
  hasError: boolean
}
export default PhoneInput

const Container = styled.div<StyledProps>`
  margin: 0 5px;
  .PhoneInput {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    font-family: Montserrat-Regular;
    line-height: 22px;
    background-color: #ffffff;
    border: 1.5px solid #fc3d7e;
    padding: 15px 10px;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: text;
    // width: 100%;
    box-sizing: border-box;
    // height: 100%;
  }

  .PhoneInputInput {
    color: #000000;
    font-size: 16px;
    font-family: Montserrat-Regular;
    background-color: #ffffff;
    border: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: text;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    min-width: 0;
  }

  .PhoneInputInput:focus {
    outline: 0;
  }

  .PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    height: 1em;
  }

  .PhoneInputCountryIcon--square {
    width: 1em;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: 0.65;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
  }
  @media only screen and (max-width: 768px) {
    margin: 7px;
  }
  .PhoneInputCountrySelect {
    flex: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
  }
    ${({ hasError }: StyledProps): any =>
      hasError &&
      css`
        border: 1px solid ${colors.error};
        background-color: #ffbbbb;
      `};
@media only screen and (max-width: 768px) {
  // width: 100%;
  margin: 7px 5px;
}
}
`
