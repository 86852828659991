import React from 'react'
import { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import colors from '../../constants/colors'
import { HeaderFooterSRC } from '../../constants/env'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { htmlEntityDecode } from '../../utils'

export default function Footer() {
  const [footer, setFooter] = useState('')
  const [, { language }] = useTranslation()

  useEffect(() => {
    const lang = language || localStorage.getItem('i18nextLng') || 'fi'

    fetch(`${HeaderFooterSRC.footer}?lang=${lang}`)
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        const head = document.head

        const pageStyleLink = document.createElement('link')
        pageStyleLink.type = 'text/css'
        pageStyleLink.rel = 'stylesheet'
        pageStyleLink.href = HeaderFooterSRC.footerStyle
        head.appendChild(pageStyleLink)

        setFooter(data)
      })
  }, [language])

  return footer ? (
    <Container dangerouslySetInnerHTML={{ __html: htmlEntityDecode(footer) }} />
  ) : (
    <ClipLoader size={5} color={colors.primary} />
  )
}

const Container = styled.div`
  @media print {
    display: none;
  }
`
