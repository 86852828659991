import NewAPI from './NewAPI'
import { AxiosResponse } from 'axios'
import APIResponse from '../Models/APIResponse'
import Receipt from '../Models/Receipt'
import PaymentRequest from '../Models/PaymentRequest'
import PaymentResponse from '../Models/PaymentResponse'
import PaymentSuccess from '../Models/PaymentSuccess'
import SuccessResponse from '../Models/SuccessResponse'
import Price from '../Models/Price'

//TODO, need to create only one API file after BE refactoring
export default class ReceiptAPI extends NewAPI {
  public postForm(data: PaymentRequest, language: string): Promise<AxiosResponse<PaymentResponse>> {
    return this.post<PaymentRequest, PaymentResponse>(`web/payment/forms?lang=${language}`, data)
  }
  public getReceipt(id: string): Promise<AxiosResponse<APIResponse<Receipt>>> {
    return this.get<APIResponse<Receipt>>(`web/transaction/receipt-info?filing_code=${id}`)
  }
  public paymentSuccess(data: PaymentSuccess): Promise<AxiosResponse<SuccessResponse>> {
    return this.post<PaymentSuccess, SuccessResponse>('web/payment/success', data)
  }
  public patchTransaction(id: string, data: any): Promise<AxiosResponse<PaymentResponse>> {
    return this.patch<any, PaymentResponse>(`web/transaction/${id}`, data)
  }
  public getPrice(): Promise<AxiosResponse<APIResponse<Price>>> {
    return this.get<APIResponse<Price>>('web/price/card/web_shop')
  }
  public utilLogger(data: any): Promise<AxiosResponse<any>> {
    return this.post<any, any>('web/util/logger', data)
  }
}
