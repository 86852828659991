import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Question from './Question'
import Input from '../../../UiKit/Input'
import styled from 'styled-components'
import DoubleInputContainer from '../../../UiKit/Input/DoubleInputContiner'
import GiftReceiverSingleForm from './GiftReceiverSingleForm'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'
import { validateDigitalGift } from '../../../../Contexts/PaymentRequestContext/reducer/validate'
import PaymentRequest from '../../../../Models/PaymentRequest'
import { RootState } from '../../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { getPrices } from '../../../../store/webshop/price/actions/getPrices'
import { postForm } from '../../../../store/webshop/payment/form/actions/postForm'
import { connect } from 'react-redux'
import Button from '../../../UiKit/Button'
import Text, { TextType } from '../../../UiKit/Text'
import 'react-datepicker/dist/react-datepicker.css'
import {
  createSetGiftBuyerEmailAction,
  createSetGiftBuyerNameAction,
  createSetGiftBuyerPhoneAction,
  createSetGiftGreetingMessageAction,
  createSetGiftSetAtAction,
} from '../../../../Contexts/PaymentRequestContext/reducer'
import ClipLoader from 'react-spinners/ClipLoader'
import colors from '../../../../constants/colors'
import PhoneInput from '../../../UiKit/PhoneInput'
import DatePicker from '../../../UiKit/DatePicker'
import TextArea from '../../../UiKit/Input/TextArea'
import { formatPrice, returnErrorMessageContent } from '../../../../utils'
import { toast } from 'react-toastify'

interface Props extends MappedState, MappedDispatch {}

const DigitalGiftForm: React.FunctionComponent<Props> = ({ price, postForm, getPrice, isFetching, error }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    getPrice()
  }, [getPrice])

  useEffect(() => {
    if (error) {
      toast.error(returnErrorMessageContent(error)[0])
    }
  }, [error])

  const {
    state: { data, errors },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onClickContinue = (): void => {
    if (validateDigitalGift(data, dispatch, t)) {
      postForm(data, i18n.language)
    }
  }

  const onChangeGiftBuyerName = (value: string): void => dispatch(createSetGiftBuyerNameAction(value))
  const onChangeGiftBuyerEmail = (value: string): void => dispatch(createSetGiftBuyerEmailAction(value))
  const onChangeGiftGreetingMessage = (value: string): void => dispatch(createSetGiftGreetingMessageAction(value))
  const onChangeGiftBuyerPhone = (value: string): void => dispatch(createSetGiftBuyerPhoneAction(value))
  const onChangeDate = (value: string): void => dispatch(createSetGiftSetAtAction(value))

  return (
    <Container>
      {data.gift_info?.map((giftInfo, index) => (
        <GiftReceiverSingleForm index={index} key={index} />
      ))}
      <GiftReceiverSingleForm index={data.amount} />
      <Question title={t('chooseTheDateAndTimeOfDeliveryOfGift')}>
        <DatePicker
          value={data.gift_send_at}
          onChange={onChangeDate}
          errorMessage={errors?.gift_send_at}
          id={'chooseTheDateAndTimeOfDeliveryOfGift'}
        />
      </Question>
      <Question
        title={t('writeYourGreetingToGiftReceiver')}
        description={t('pleaseNoticeThatTheSameMessageIsSentToAllReceivers')}
      >
        <TextArea
          className="input-margin"
          inputClassName="message-input"
          onChange={onChangeGiftGreetingMessage}
          value={data.gift_greetings_message || ''}
          errorMessage={errors?.gift_greetings_message}
        />
      </Question>
      <Question title={t('yourOwnContactInformation')} description={t('weDeliverAReceiptOfYourPurchaseToYourEmail')}>
        <DoubleInputContainer>
          <Input
            placeholder={t('requiredName')}
            className="input-margin"
            onChange={onChangeGiftBuyerName}
            value={data.gift_buyer_name || ''}
            errorMessage={errors?.gift_buyer_name}
            id={'requiredName'}
          />
        </DoubleInputContainer>
        <DoubleInputContainer>
          <Input
            placeholder={t('requiredEmailAddress')}
            onChange={onChangeGiftBuyerEmail}
            value={data.gift_buyer_email || ''}
            errorMessage={errors?.gift_buyer_email}
            id={'requiredEmailAddress'}
          />
          <PhoneInput
            placeholder={t('requiredPhone')}
            value={data.gift_buyer_phone || ''}
            onChange={onChangeGiftBuyerPhone}
            id={'requiredPhone'}
          />
        </DoubleInputContainer>
      </Question>
      {price && (
        <div className="title-margin">
          <Text type={TextType.BOLD}>
            {t('giftOrder', { price: data.amount }) + formatPrice((price / 100) * data.amount)}
          </Text>
        </div>
      )}
      {isFetching && <ClipLoader size={50} color={colors.primary} />}
      <Button onClick={onClickContinue} id={'continue'}>
        {t('continue')}
      </Button>
    </Container>
  )
}

interface MappedState {
  price?: number
  isFetching: boolean
  error: any
}

interface MappedDispatch {
  getPrice: () => void
  postForm: (data: PaymentRequest, language: string) => void
}

const mapStateToProps = (state: RootState): MappedState => ({
  price: state.webshop.price.data && state.webshop.price.data.digital_gift,
  isFetching: state.webshop.payment.form.is_fetching,
  error: state.webshop.payment.form.error,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getPrice: (): any => dispatch(getPrices()),
  postForm: (data: PaymentRequest, language: string): any => dispatch(postForm(data, language)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DigitalGiftForm)

const Container = styled.div`
  .input-margin {
    margin: 10px 5px;
  }
  .title-margin {
    margin: 10px 0 5px;
    text-align: left;
  }
  .message-input {
    height: 110px;
  }
`
