import React from 'react'
import InputContainer from './InputContainer'

interface Props {
  value?: string
  errorMessage?: string
  placeholder?: string
  onChange?: (value: string) => void
  errorClassName?: string
  inputClassName?: string
  className?: string
}

const TextArea: React.FunctionComponent<Props> = ({
  value,
  errorMessage = '',
  placeholder,
  onChange,
  errorClassName,
  className,
  inputClassName,
}) => {
  return (
    <InputContainer errorMessage={errorMessage} errorClassName={errorClassName} className={className}>
      <textarea
        className={inputClassName}
        placeholder={placeholder}
        onChange={(e): void => onChange && onChange(e.target.value)}
        rows={4}
        value={value}
      />
    </InputContainer>
  )
}

export default TextArea
