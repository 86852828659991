import React from 'react'
import styled, { css } from 'styled-components'

export enum TextType {
  TITLE = 'title',
  BOLD = 'bold',
  REGULAR = 'regular',
  BIG_BOLD = 'big-bold',
}

interface Props {
  type?: TextType
  className?: string
}

const Text: React.FunctionComponent<Props> = ({ type = TextType.REGULAR, children, className }) => {
  return (
    <TextStyle className={className} textType={type}>
      {children}
    </TextStyle>
  )
}

interface StyleProps {
  textType: string
}

const TextStyle = styled.span`
  font-family: 'Muli-Bold';
  ${({ textType }: StyleProps): any =>
    textType === TextType.TITLE &&
    css`
      font-family: Montserrat-extra-bold;
      line-height: 1.28571429em;
      font-size: 22pt;
      font-weight: 800;
      color: black;
    `};
  ${({ textType }: StyleProps): any =>
    textType === TextType.BOLD &&
    css`
      color: black;
      font-weight: bold;
      font-family: Montserrat-Bold;
      font-size: 10pt;
    `};
  ${({ textType }: StyleProps): any =>
    textType === TextType.REGULAR &&
    css`
      color: black;
      font-weight: 'normal';
      font-family: Montserrat-Regular;
      font-size: 10pt;
    `};
  ${({ textType }: StyleProps): any =>
    textType === TextType.BIG_BOLD &&
    css`
      color: black;
      font-weight: bold;
      font-family: Montserrat-Bold;
      font-size: 11pt;
    `};
`

export default Text
