/* eslint @typescript-eslint/camelcase: 0 */
import validator from 'validator'
import { createSetErrorsAction } from './index'
import PaymentRequest from '../../../Models/PaymentRequest'
import DeepPartial from '../../../Models/DeepPartial'

const isValidString = (value?: string): boolean => value && validator.isLength(value, { min: 1, max: 255 })

const regexp = /^[ a-zA-ZZÆØÅÖÄÜÉĄČĘĖĮŠŲŽĀĒĢĪĶĻŅŪæøåöäüéąčęėįšųžāēģīķļņū,.'%#-]+$/u

export const validatePersonal = (data: PaymentRequest, dispatch, t): boolean => {
  const errors: DeepPartial<PaymentRequest> = {}
  // TODO, need to change all error texts (waiting response from Jussi)
  if (!isValidString(data.customer_first_name) || data.customer_first_name?.match(regexp) === null) {
    errors.customer_first_name = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.customer_last_name) || data.customer_first_name?.match(regexp) === null) {
    errors.customer_last_name = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.customer_address)) {
    errors.customer_address = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.customer_city)) {
    errors.customer_city = t('validationFirstNameShouldNotBeEmpty')
  }
  if (
    !isValidString(data.customer_email) ||
    !validator.isEmail(data.customer_email) ||
    data.customer_first_name?.match(regexp) === null
  ) {
    errors.customer_email = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.customer_date_of_birth || !validator.isInt(data.customer_date_of_birth, { min: 1900, max: 2020 })) {
    errors.customer_date_of_birth = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.customer_zipcode) {
    errors.customer_zipcode = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.password || data.password.length < 8) {
    errors.password = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.password_confirmation || data.password_confirmation !== data.password) {
    errors.password_confirmation = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.terms_and_conditions) {
    errors.terms_and_conditions = t('validationFirstNameShouldNotBeEmpty')
  }
  dispatch(createSetErrorsAction(errors))
  return Object.keys(errors).length === 0
}

export const validateLetterGift = (data: PaymentRequest, dispatch, t): boolean => {
  const errors: DeepPartial<PaymentRequest> = {}

  if (!isValidString(data.gift_receiver_first_name) || data.gift_receiver_first_name?.match(regexp) === null) {
    errors.gift_receiver_first_name = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.gift_receiver_last_name) || data.gift_receiver_last_name?.match(regexp) === null) {
    errors.gift_receiver_last_name = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.gift_buyer_email) || !validator.isEmail(data.gift_buyer_email)) {
    errors.gift_buyer_email = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.gift_receiver_city)) {
    errors.gift_receiver_city = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.gift_buyer_name)) {
    errors.gift_buyer_name = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!isValidString(data.gift_buyer_email)) {
    errors.gift_buyer_email = t('validationFirstNameShouldNotBeEmpty')
  }
  if (!data.gift_receiver_zip_code || !validator.isInt(data.gift_receiver_zip_code, { min: 1 })) {
    errors.gift_receiver_zip_code = t('validationFirstNameShouldNotBeEmpty')
  }
  dispatch(createSetErrorsAction(errors))
  return Object.keys(errors).length === 0
}
export const validateDigitalGift = (data: PaymentRequest, dispatch, t): boolean => {
  const errors: DeepPartial<PaymentRequest> = {}
  //TODO, check validations after BE refactoring

  // if (!isValidString(data.gift_greetings_message)) {
  //   errors.gift_send_at = t('validationFirstNameShouldNotBeEmpty')
  // }
  if (!isValidString(data.gift_buyer_name)) {
    errors.gift_buyer_name = t('validationFirstNameShouldNotBeEmpty')
  }
  //TODO, check validations after BE refactoring

  // if (!isValidString(data.gift_buyer_phone)) {
  //   errors.gift_buyer_phone = t('validationFirstNameShouldNotBeEmpty')
  // }
  if (!isValidString(data.gift_buyer_email) || !validator.isEmail(data.gift_buyer_email)) {
    errors.gift_buyer_email = t('validationFirstNameShouldNotBeEmpty')
  }

  data.gift_info?.forEach((giftInfo, index) => {
    if (!giftInfo.receiver_email || !validator.isEmail(giftInfo.receiver_email)) {
      if (!errors.gift_info) errors.gift_info = []
      errors.gift_info[index] = {
        ...errors.gift_info[index],
        receiver_email: t('validationReceiverEmailError'),
      }
    }
    if (!isValidString(giftInfo.receiver_name)) {
      if (!errors.gift_info) errors.gift_info = []
      errors.gift_info[index] = {
        ...errors.gift_info[index],
        receiver_name: t('validationReceiverNameError'),
      }
    }
    if (!isValidString(giftInfo.receiver_phone)) {
      if (!errors.gift_info) errors.gift_info = []
      errors.gift_info[index] = {
        ...errors.gift_info[index],
        receiver_phone: t('validationReceiverNameError'),
      }
    }
  })

  dispatch(createSetErrorsAction(errors))
  return Object.keys(errors).length === 0
}
