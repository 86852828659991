import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  createPostPaymentSuccessRequestAction,
  createPostPaymentSuccessSuccessAction,
  createPostPaymentSuccessFailureAction,
} from './creators'
import ReceiptAPI from '../../../../../api/ReceiptAPI'
import browserHistory from '../../../../../browserHistory'
import RoutePaths from '../../../../../Routes/RoutePaths'
import PaymentSuccess from '../../../../../Models/PaymentSuccess'
import { setFilingCode } from '../../../../../utils/storage'

const paymentSuccess = (data: PaymentSuccess): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
): Promise<void> => {
  dispatch(createPostPaymentSuccessRequestAction(data))

  try {
    const receiptAPI = new ReceiptAPI()
    const response = await receiptAPI.paymentSuccess(data)
    setFilingCode(response.data.filing_code)
    dispatch(createPostPaymentSuccessSuccessAction())
    browserHistory.push(RoutePaths.THANK_YOU)
  } catch (error) {
    dispatch(createPostPaymentSuccessFailureAction('paymentFailed'))
  }
}

export default paymentSuccess
