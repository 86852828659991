import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createUpdateTransactionRequestAction,
  createUpdateTransactionSuccessAction,
  createUpdateTransactionFailureAction,
  createUpdateTransactionPaymentFailureAction,
} from './creators'
import ReceiptAPI from '../../../../../api/ReceiptAPI'

import browserHistory from '../../../../../browserHistory'
import RoutePaths from '../../../../../Routes/RoutePaths'
import { createPostFormSuccessAction } from '../../form/actions/creators'
import { getPaymentFormResponse, removePaymentFormResponse } from '../../../../../utils/storage'

const updateTransaction = (data: any): ThunkAction<any, any, any, any> => async (dispatch: Dispatch): Promise<void> => {
  dispatch(createUpdateTransactionRequestAction(data))

  try {
    const receiptAPI = new ReceiptAPI()
    await receiptAPI.patchTransaction(data.id, data)
    dispatch(createUpdateTransactionSuccessAction())
    if (data.status === 'failed') {
      removePaymentFormResponse()
      dispatch(createUpdateTransactionPaymentFailureAction('paymentFailed'))
      browserHistory.push(RoutePaths.HOME) // todo: add error as query param
    } else {
      const paymentResponse = getPaymentFormResponse()
      if (paymentResponse) {
        dispatch(createPostFormSuccessAction(paymentResponse))
      }
      browserHistory.push(RoutePaths.HOME) // todo: add error as query param
    }
  } catch (error) {
    dispatch(createUpdateTransactionFailureAction(error))
  }
}

export default updateTransaction
