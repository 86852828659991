import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
  isChecked: boolean
  onToggle: () => void
  className?: string
  id: string
}
const Checkbox: React.FunctionComponent<Props> = ({ isChecked, onToggle, children, className, id }) => {
  return (
    <Container className={className}>
      <div className={`checkbox ${isChecked ? 'white-back' : 'red-back'}`}>
        <button onClick={onToggle} className="inside-button" id={id}>
          {isChecked && <div className="checked-radius" />}
        </button>
      </div>
      <div className="checkbox-text">{children}</div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  .checkbox {
    height: 1.5em;
    width: 1.5em;
    padding: 0 4px 4px 0;
    border-radius: 3em;
    align-items: center;
  }
  .red-back {
    background-color: #ffdbd8;
  }
  .white-back {
    background-color: #ff598f;
  }
  ,
  .checkbox-text {
    margin-left: 10px;
    text-align: left;
  }
  .checked-radius {
    position: relative;
    left: 5px;
    height: 1.1em;
    width: 1.1em;
    border-radius: 3em;
    background-color: #ffffff;
  }
  ,
  .checked-icon {
    height: 15px;
    margin: auto;
    padding-top: 7px;
  }
  .inside-button {
    border: 0;
    margin: 1px 3px 3px 0px;
    background-color: transparent;
    width: 30px;
    height: 27px;
    &:focus {
      outline: 0;
    }
  }
`

export default Checkbox
