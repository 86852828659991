import React from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import LinkText from './LinkText'
import { useTranslation } from 'react-i18next'

interface Props {
  hyperlink: string
  text: string
}

const HyperLinkText: React.FunctionComponent<Props> = ({ hyperlink, text }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <a target="_blank" rel="noopener noreferrer" href={hyperlink} className="text-decoration">
        <LinkText>{t(text)}</LinkText>
      </a>
    </Container>
  )
}

const Container = styled.a`
  .link-text {
    background-color: ${colors.white};
    color: ${colors.primary};
  }
  cursor: pointer;
  display: block;
  .text-decoration {
    text-decoration: none;
  }
`

export default HyperLinkText
