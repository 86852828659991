import Types from './actions/types'
import { ActionTypes } from './actions/creators'

export const initialState: State = {
  is_fetching: false,
}

export default (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Types.UPDATE_TRANSACTION_REQUEST:
      return { ...initialState, is_fetching: true }
    case Types.UPDATE_TRANSACTION_SUCCESS:
      return { ...initialState }
    case Types.UPDATE_TRANSACTION_FAILURE:
      return { ...initialState, error: action.error }
    case Types.UPDATE_TRANSACTION_PAYMENT_FAILURE:
      return { ...initialState, transactionPaymentError: action.error }
    case Types.CLEAR_ERROR_MESSAGE:
      return { ...initialState }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  error?: any
  transactionPaymentError?: string
}
