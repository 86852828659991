import React from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { useTranslation } from 'react-i18next'
import printLogo from '../../assets/images/printLogo.png'
import Button from '../UiKit/Button'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { getReceipt } from '../../store/webshop/receipt/actions/receiptForm'
import { RootState } from '../../store'
import Receipt from '../../Models/Receipt'
import ClipLoader from 'react-spinners/ClipLoader'

interface Props extends MappedState, MappedDispatch {}

const GiftReceiptForm: React.FunctionComponent<Props> = ({ getReceipt, data }) => {
  const { t } = useTranslation()

  // const printDiv = divName => {
  //   var content = document.getElementById('divcontents')
  //   var pri = document.getElementById('ifmcontentstoprint').contentWindow
  //   pri.document.open()
  //   pri.document.write(content.innerHTML)
  //   pri.document.close()
  //   pri.focus()
  //   pri.print()
  // }
  React.useEffect(() => {
    getReceipt()
  }, [getReceipt])

  if (!data) {
    return <ClipLoader size={20} color={colors.primary} />
  } else {
    return (
      <Container id="ifmcontentstoprint">
        <table className="no-print">
          <tr>
            <td style={{ width: 300 }}>
              <Button className="primary button" onClick={(): any => window.print()} id="print">
                {t('print')}
              </Button>
            </td>
          </tr>
        </table>
        <table cellSpacing="0" cellPadding="0" id="header" className="tableStyle">
          <tr>
            <td style={{ width: 300 }}>
              <img src={printLogo} alt="" className="img-max-size" />
              <span className="bold span-header">{t('receipt')}</span>
            </td>
            <td style={{ width: 300 }} align="right" className="bold td-date">
              {data?.date}
            </td>
          </tr>
        </table>
        <table cellSpacing="0" cellPadding="0" className="table-margin">
          <tr>
            <td style={{ width: 390 }}></td>
            <td align="left" style={{ width: 210 }}>
              <span className="span-number-style">{t('receiptPayment')}</span>
            </td>
          </tr>
        </table>
        <table cellSpacing="0" cellPadding="0" className="table-margin">
          <tr>
            <td align="left" style={{ width: 600 }}>
              <strong>{t('nameOfOrderer')}</strong>
              <br />
              <br />
              <span>{data?.buyer?.name}</span>
              <br />
              <span>{data?.buyer?.email}</span>
            </td>
          </tr>
        </table>

        <table
          cellSpacing="0"
          cellPadding="0"
          style={{ width: 600, border: 0, marginTop: 20 }}
          className="table-margin-120"
        >
          <tr style={{ textAlign: 'left' }}>
            <th>{t('nameOfReceiver')}</th>
            <th>{t('productPersonal')}</th>
            <th>{t('price')}</th>
            <th>{t('count')}</th>
            <th>{t('vat')}</th>
            <th>{t('totalBefore')}</th>
          </tr>
          {data.receivers.map(item => (
            <>
              <br />
              <tr>
                <td id="code">
                  {item.name}
                  <br />
                  {item.email}
                </td>
                <td>{t('card')}</td>
                <td> {data?.price} &#8364;</td>
                <td style={{ textAlign: 'center' }}>{data?.amount}</td>
                <td>0</td>
                <td>{data?.total} &#8364;</td>
              </tr>
              {data.type === 'letter_gift' ? (
                <span>
                  {item.organisation}
                  <br />
                  {item.street_address}
                  <br />
                  {item.zip_code} {item.city}
                </span>
              ) : (
                <>
                  <br />
                  <span>
                    {t('delivery')} {item?.send_at}
                  </span>
                </>
              )}

              <br />
            </>
          ))}
        </table>

        <table cellSpacing="0" cellPadding="0" className="table-margin-20">
          <tr>
            <td style={{ width: 470 }}></td>
            <td style={{ width: 130 }} align="left">
              <span>
                {t('taxFree')} {data?.price} &#8364;
              </span>
              <br />
              <span>{t('vatTotal')} 0,00 &#8364;</span>
              <br />
              <span>
                {t('totalAfter')} {data?.total} &#8364;
              </span>
            </td>
          </tr>
        </table>
        <table cellSpacing="0" cellPadding="0" id="footer" className="footer-table margin-bottom">
          <tr>
            <td align="left" valign="top" style={{ width: 200 }}>
              <span>FMA Creations Oy</span>
              <br />
              <span>Annankatu 16 B 50</span>
              <br />
              <span>00120 Helsinki</span>
              <br />
            </td>
            <td align="left" valign="top" style={{ width: 200 }}>
              <span>{t('customerService')}:</span>
              <br />
              <span>museokortti@museot.fi</span>
              <br />
              <span>044 784 5745 (ark. klo 9 - 12)</span>
              <br />
            </td>
            <td align="left" valign="top" style={{ width: 200 }}>
              <span>{t('businessNumber')}: 2673987-4</span>
            </td>
          </tr>
        </table>
      </Container>
    )
  }
}

interface MappedDispatch {
  getReceipt: () => void
}
interface MappedState {
  data?: Receipt
  isFetching: boolean
}
const mapStateToProps = (state: RootState): MappedState => ({
  data: state.webshop.receipt.data,
  isFetching: state.webshop.receipt.is_fetching,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getReceipt: (): any => dispatch(getReceipt()),
})

const Container = styled.div`
  .link-text {
    background-color: ${colors.white};
    color: ${colors.primary};
  }
  table {
    min-width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  th {
    font-weight: 600;
  }

  td {
    max-width: 600px;
  }

  .bold {
    font-weight: 700;
  }

  .button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease,
      background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease,
      background 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
  }

  .primary.button,
  .ui.primary.buttons .button {
    background-color: #2185d0;
    color: #fff;
    text-shadow: none;
    background-image: none;
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }
  .tableStyle {
    padding: 50px 0px 20px 0px;
    border-bottom: 1px solid #000;
  }
  .span-header {
    font-size: 1.4em;
    margin-left: 25px;
  }
  .img-max-size {
    max-width: 220px;
  }
  .td-date {
    padding-top: 70px;
    font-size: 1.4em;
  }
  .table-margin {
    margin-top: 35px;
  }
  .span-number-style {
    font-size: 1em;
    margin-left: 25px;
  }
  .table-margin-20 {
    margin-top: 20px;
  }
  .footer-table {
    border-top: 1px solid red;
    margin-top: 150px;
    padding: 20px 0px 50px 0px;
  }
  .table-margin-120 {
    margin-top: 120px;
  }
  .margin-bottom {
    margin-bottom: 18px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(GiftReceiptForm)
