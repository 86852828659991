import React from 'react'
import styled from 'styled-components'
import PlusMinusIcon from './PlusMinusIcon'
import plusIcon from '../../../../assets/images/plusIcon.png'
import minusIcon from '../../../../assets/images/minusIcon.png'
import colors from '../../../../constants/colors'
import Text, { TextType } from '../../../UiKit/Text'

interface Props {
  numberOfPeople: number
  onIncrement: () => void
  onDecrement: () => void
}

const NumberSelectorInput: React.FunctionComponent<Props> = ({ numberOfPeople, onIncrement, onDecrement }) => {
  return (
    <Container>
      <PlusMinusIcon imageSource={minusIcon} onClick={onDecrement} id={'plus'} />
      <div className="box">
        <Text type={TextType.BOLD}>{numberOfPeople}</Text>
      </div>
      <PlusMinusIcon imageSource={plusIcon} onClick={onIncrement} id={'minus'} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
  @media only screen and (max-width: 768px) {
    margin-left: 1rem;
  }
  .box {
    border: 1px solid ${colors.primary} !important;
    border-radius: 0px !important;
    height: 1.7em;
    width: 1.7em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin 0 2px;
    @media only screen and (max-width: 768px) {
      height: 1.5em;
      width: 1.5em;
    }
  }
`

export default NumberSelectorInput
