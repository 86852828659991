import React from 'react'
import styled from 'styled-components'
import logoEdenred from '../../assets/images/homePage/logo-edenred.png'
import logoSmartum from '../../assets/images/homePage/logo-smartum.png'
import logoEpassi from '../../assets/images/homePage/logo-epassi.png'
import logoVisa from '../../assets/images/homePage/logo-visa.png'
import logoMastercard from '../../assets/images/homePage/logo-mastercard.png'
import logoOsuuspankki from '../../assets/images/homePage/logo-osuuspankki.png'
import logoSaastopankki from '../../assets/images/homePage/logo-saastopankki.png'
import logoAlandsbanken from '../../assets/images/homePage/logo-alandsbanken.png'
import logoOmaop from '../../assets/images/homePage/logo-omaop.png'
import logoMobilepay from '../../assets/images/homePage/logo-mobilepay.png'
import logoDanskeBank from '../../assets/images/homePage/logo-danske-bank.png'
import logoNordea from '../../assets/images/homePage/logo-nordea.png'
import logoPoppankki from '../../assets/images/homePage/logo-poppankki.png'
import logoSpankki from '../../assets/images/homePage/logo-spankki.png'
import logoAktia from '../../assets/images/homePage/logo-aktia.png'
import logoHandelsbanken from '../../assets/images/homePage/logo-handelsbanken.png'
import collectorb2c from '../../assets/images/homePage/walley-lasku-ja-osamaksu.png'

const PaymentMethodLogos: React.FunctionComponent = () => {
  return (
    <Section>
      <div className="mod-logoGallery__contentWrapper">
        <ul className="mod-logoGallery__list">
          <li className="mod-logoGallery__singleItem">
            <img className="mod-shopOptions__benefitImage" src={logoEdenred} alt="Edenred" />
          </li>
          {/* <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoEazybreak} alt="Eazybreak" />
          </li> */}
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoSmartum} alt="Smartum" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoEpassi} alt="ePassi" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoVisa} alt="Visa" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoMastercard} alt="Mastercard" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoOsuuspankki} alt="Osuuspankki" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoSaastopankki} alt="Säästöpankki" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoAlandsbanken} alt="Ålandsbanken" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoOmaop} alt="Omaop" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoMobilepay} alt="Mobilepay" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoDanskeBank} alt="Danske Bank" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoNordea} alt="Nordea" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoPoppankki} alt="POP Pankki" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoSpankki} alt="S-pankki" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoAktia} alt="Aktia" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={logoHandelsbanken} alt="Handelsbanken" />
          </li>
          <li className="mod-logoGallery__singleItem">
            <img className="mod-logoGallery__image" src={collectorb2c} alt="collectorb2c" />
          </li>
        </ul>
      </div>
    </Section>
  )
}

export default PaymentMethodLogos

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 4.5rem;
  position: relative;
  &:before {
    width: 3000px;
    background-color: $color-white;
    height: rem(140px);
    transform: rotate(-2deg);
    position: absolute;
    top: rem(-90px);
    left: -50%;
    content: '';
  }

  @media (min-width: 1200px) {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }

  .mod-shopOptions__benefitImage {
    max-height: 5rem;
    width: auto;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__benefitImage {
      max-height: 5.5rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__benefitImage {
      max-height: 6.25rem;
    }
  }

  .mod-logoGallery__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
  }
  @media (min-width: 1024px) {
    .mod-logoGallery__contentWrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: 1.5rem;
    }
  }
  .mod-logoGallery__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 15rem;
  }
  @media (min-width: 540px) {
    .mod-logoGallery__list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 100%;
      grid-gap: 3.75rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-logoGallery__list {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      max-width: 100%;
      grid-gap: 4.5rem;
      grid-column: 2 / span 10;
    }
  }
  .mod-logoGallery__singleItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mod-logoGallery__image {
    max-height: 5rem;
    width: auto;
  }
  @media (min-width: 1024px) {
    .mod-logoGallery__image {
      max-height: 7.5rem;
    }
  }
`
