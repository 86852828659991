import React from 'react'
import { useLocation } from 'react-router-dom'
import * as queryString from 'query-string'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import paymentSuccess from '../../../store/webshop/payment/success/actions/paymentSuccess'
import PaymentSuccessModel from '../../../Models/PaymentSuccess'
import colors from '../../../constants/colors'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/core'
import { RootState } from '../../../store'
import { useHistory } from 'react-router-dom'
import RoutePaths from '../../../Routes/RoutePaths'
import utilLogger from '../../../store/webshop/payment/success/actions/utilLogger'

interface Props extends MappedState, MappedDispatch {}

const PaymentSuccess: React.FunctionComponent<Props> = ({ payemntDone, isFetching, paymentSuccess, utilLogger }) => {
  const location = useLocation()
  const history = useHistory()

  if (!!payemntDone) {
    history.replace(RoutePaths.HOME)
    return null
  }
  const spinner = css`
    margin: auto;
    display: block;
  `
  const successObject = queryString.parse(location.search)
  if (!isFetching) {
    utilLogger({ ...successObject, source: 'webshop', endpoint: 'payment/success' })
    if (successObject.method === 'paytrail') {
      Object.keys(successObject).forEach((el: string) => {
        const newKey = el.replaceAll('-', '_')
        successObject[newKey] = successObject[el]
        if (newKey !== el) delete successObject[el]
      })

      const newPaytrailObject: PaymentSuccessModel = {
        ...successObject,
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: successObject.transaction_id,
        source: 'web_shop',
        method: 'paytrail',
        status: successObject.status,
      }
      paymentSuccess(newPaytrailObject)
    } else if (successObject.method === 'solinor') {
      const newSolinorObject: PaymentSuccessModel = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: successObject.transaction_id,
        source: 'web_shop',
        method: 'solinor',
        status: 'success',
        signature: successObject.signature,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_account: successObject['sph-account'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_merchant: successObject['sph-merchant'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_request_id: successObject['sph-request-id'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_success: successObject['sph-success'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_timestamp: successObject['sph-timestamp'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_transaction_id: successObject['sph-transaction-id'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_order: successObject['sph-order'],
      }
      paymentSuccess(newSolinorObject)
    } else if (successObject.method === 'edenred') {
      const newEdenredObject: PaymentSuccessModel = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: successObject.transaction_id,
        source: 'web_shop',
        method: 'edenred',
        status: 'success',
        signature: successObject.signature,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_account: successObject['sph-account'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_merchant: successObject['sph-merchant'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_request_id: successObject['sph-request-id'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_success: successObject['sph-success'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_timestamp: successObject['sph-timestamp'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        // sph_cancel: successObject['sph-cancel'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_transaction_id: successObject['sph-transaction-id'],
        // eslint-disable-next-line @typescript-eslint/camelcase
        sph_order: successObject['sph-order'],
      }
      paymentSuccess(newEdenredObject)
    } else if (successObject.method === 'smartum') {
      const newSmartumObject: PaymentSuccessModel = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: successObject.transaction_id,
        source: 'web_shop',
        method: 'smartum',
        status: 'success',
        jwt: successObject.jwt,
      }
      paymentSuccess(newSmartumObject)
    } else if (successObject.method === 'epassi') {
      const newEpassiObject: PaymentSuccessModel = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: successObject.transaction_id,
        source: 'web_shop',
        method: 'epassi',
        status: 'success',
        STAMP: successObject.STAMP,
        PAID: successObject.PAID,
        MAC: successObject.MAC,
      }
      paymentSuccess(newEpassiObject)
    } else if (successObject.method === 'eazybreak') {
      const transId = String(successObject?.transaction_id)
      const transactionId = transId.split('?')[0]
      const id = transId.split('=').pop()
      const newEazybreakObject: PaymentSuccessModel = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        transaction_id: transactionId,
        source: 'web_shop',
        method: 'eazybreak',
        status: 'completed',
        id,
        checksum: successObject.checksum,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: successObject.first_name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: successObject.last_name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        payment_id: successObject.payment_id,
        time: successObject.time,
        value: successObject.value,
      }
      paymentSuccess(newEazybreakObject)
    }
  }

  return <ClipLoader size={150} color={colors.primary} css={spinner} />
}
interface MappedState {
  payemntDone: boolean
  isFetching: boolean
}

interface MappedDispatch {
  paymentSuccess: (data: PaymentSuccessModel) => void
  utilLogger: (data: any) => void
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  paymentSuccess: (data: PaymentSuccessModel): any => dispatch(paymentSuccess(data)),
  utilLogger: (data: any): any => dispatch(utilLogger(data)),
})
const mapStateToProps = (state: RootState): MappedState => {
  return {
    payemntDone: state.webshop.payment.success.payment_done,
    isFetching: state.webshop.payment.success.is_fetching,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
