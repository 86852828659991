import Types from './actions/types'
import { ActionTypes } from './actions/creators'

export const initialState: State = {
  is_fetching: false,
  payment_done: false,
}

export default (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Types.PAYMENT_SUCCESS_REQUEST:
      return { ...initialState, is_fetching: true, payment_done: false }
    case Types.PAYMENT_SUCCESS_SUCCESS:
      return { ...initialState, payment_done: true }
    case Types.PAYMENT_SUCCESS_FAILURE:
      return { ...initialState, error: action.error, payment_done: true }
    case Types.CLEAR_ERROR_MESSAGE:
      return { ...initialState }
    default:
      return state
  }
}

export interface State {
  is_fetching: boolean
  error?: any
  payment_done: boolean
}
