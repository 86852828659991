import { combineReducers } from 'redux'
import price, { State as PriceState, initialState as priceInitialState } from './price'
import payment, { State as PaymentState, initialState as paymentInitialState } from './payment'
import receipt, { State as ReceiptState, initialState as receiptInitialState } from './receipt'

export interface State {
  price: PriceState
  payment: PaymentState
  receipt: ReceiptState
}

export const initialState: State = {
  price: priceInitialState,
  payment: paymentInitialState,
  receipt: receiptInitialState,
}

export default combineReducers({
  price,
  payment,
  receipt,
})
