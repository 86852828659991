const redirectPost = (url: string, data: any): void => {
  const form = document.createElement('form')
  document.body.appendChild(form)
  form.method = 'post'
  form.action = url
  for (const name in data) {
    if (data.hasOwnProperty(name)) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = name
      input.value = data[name]
      form.appendChild(input)
    }
  }
  form.submit()
}

export default redirectPost
