import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from 'react-i18next'
import GlobalFonts from './assets/fonts/fonts'
import store from './store'
import Routes from './Routes'
import browserHistory from './browserHistory'
import i18n from './i18n'
import ScrollToTop from './ScrollToTop'
import Header from './Components/CustomComponents/Header'
import Footer from './Components/CustomComponents/Footer'
import Popup from './Components/Cookies'
import Toast from './Components/UiKit/Toast'

ReactDOM.render(
  <React.StrictMode>
    <GlobalFonts />
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router history={browserHistory}>
          <Header />
          <ScrollToTop />
          <Routes />
          <Footer />
          <Toast />
        </Router>
        <Popup />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
