import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../UiKit/Text'

interface Props {
  title: string
  explanation?: string
}

const MethodExplanation: React.FunctionComponent<Props> = ({ title, explanation }: Props) => {
  return (
    <Container>
      <Text type={TextType.BOLD} className="description-display">
        {title}
      </Text>
      <Text>{explanation}</Text>
    </Container>
  )
}

const Container = styled.div`
  text-align: left;
  margin-top: 30px;
  .description-display {
    display: block;
    @media only screen and (max-width: 768px) {
      margin: 5px 0;
    }
  }
`

export default MethodExplanation
