import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BuyHeader from '../../../BuyHeader'
import giftIcon from '../../../../assets/images/giftIcon.png'
import ReturnButton from '../../../UiKit/Button/ReturnButton'
import Text, { TextType } from '../../../UiKit/Text'
import StepsDisplay from '../../StepsDisplay'
import EnvelopeSingleForm from './EnvelopeSingleForm'
import Button from '../../../UiKit/Button'
import PaymentRequest from '../../../../Models/PaymentRequest'
import { RootState } from '../../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { getPrices } from '../../../../store/webshop/price/actions/getPrices'
import { postForm } from '../../../../store/webshop/payment/form/actions/postForm'
import { connect } from 'react-redux'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'
import { validateLetterGift } from '../../../../Contexts/PaymentRequestContext/reducer/validate'
import ClipLoader from 'react-spinners/ClipLoader'
import colors from '../../../../constants/colors'
import { formatPrice, returnErrorMessageContent } from '../../../../utils'
import { toast } from 'react-toastify'

interface Props extends MappedState, MappedDispatch {}

const EnvelopeGiftForm: React.FunctionComponent<Props> = ({ price, getPrice, postForm, isFetching, error }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (error) {
      toast.error(returnErrorMessageContent(error)[0])
    }
  }, [error])

  useEffect(() => {
    getPrice()
  }, [getPrice])

  const {
    state: { data },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onClickContinue = (): void => {
    if (validateLetterGift(data, dispatch, t)) {
      postForm(data, i18n.language)
    }
  }

  return (
    <Container>
      <div className="container">
        <div className="return-button">
          <ReturnButton />
        </div>
        <BuyHeader imageSource={giftIcon} title={t('theDeliveryOfTheGiftOrder')} />
        <Text className="block-display text-margin font-size">
          {t('theGiftEnvelopeOrderIsDeliveredToThePostalAddress')}
          <Text type={TextType.BOLD} className="font-size">
            {t('oneToThreeWeeks')}.
          </Text>
        </Text>
        <Text className="block-display text-margin font-size">{t('youWillReceive')}</Text>
        <Text className="block-display text-margin font-size">{t('theGiftEnvelopeContainsTemporaryMuseumCard')}</Text>
        <StepsDisplay totalSteps={2} currentStep={1} />
        <EnvelopeSingleForm />
        {isFetching && <ClipLoader size={50} color={colors.primary} />}
        <Button onClick={onClickContinue} id={'continue'}>
          {t('continue')}
        </Button>
        {price && (
          <div className="title-margin">
            <Text type={TextType.BOLD}>
              {t('giftOrder', { price: data.amount }) + formatPrice((price / 100) * data.amount)}
            </Text>
          </div>
        )}
        <div className="return-button">
          <ReturnButton />
        </div>
      </div>
    </Container>
  )
}

interface MappedState {
  isFetching: boolean
  price?: number
  error: any
}

interface MappedDispatch {
  getPrice: () => void
  postForm: (data: PaymentRequest, language: string) => void
}

const mapStateToProps = (state: RootState): MappedState => ({
  isFetching: state.webshop.price.is_fetching || state.webshop.price.is_fetching,
  price: state.webshop.price.data && state.webshop.price.data.letter_gift,
  error: state.webshop.payment.form.error,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getPrice: (): any => dispatch(getPrices()),
  postForm: (data: PaymentRequest, language: string): any => dispatch(postForm(data, language)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeGiftForm)

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
  .block-display {
    display: block;
  }
  .text-margin {
    margin: 10px 0;
  }
  .title-margin {
    margin: 10px 0 5px;
    text-align: left;
  }
  .font-size {
    font-size: 12pt;
  }
`
