import Types from './types'
import Price from '../../../../Models/Price'

export type ActionTypes =
  | { type: Types.GET_PRICES_REQUEST }
  | { type: Types.GET_PRICES_SUCCESS; data: Price }
  | { type: Types.GET_PRICES_FAILURE; error: any }

export const createGetPricesRequestAction = (): ActionTypes => ({ type: Types.GET_PRICES_REQUEST })
export const createGetPricesSuccessAction = (data: any): ActionTypes => ({ type: Types.GET_PRICES_SUCCESS, data })
export const createGetPricesFailureAction = (error: any): ActionTypes => ({
  type: Types.GET_PRICES_FAILURE,
  error,
})
