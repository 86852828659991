import React from 'react'
import styled from 'styled-components'
import illustrationBuy from '../../assets/images/homePage/illustration-buy.svg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

enum Language {
  FINNISH = 'fi',
  ENGLISH = 'en',
  SWEDISH = 'sv',
}

const WelcomeSection: React.FunctionComponent = () => {
  const { i18n, t } = useTranslation()
  const history = useHistory()

  const changeLang = lang => {
    i18n.changeLanguage(lang)
    const path = history.location.pathname.split('/')
    const newLocale = path[path.length - 1]
    const newPath = history.location.pathname.replace(newLocale, lang)
    history.push(newPath)
  }

  return (
    <Section>
      <div className="mod-shopHero__contentWrapper">
        <div className="mod-shopHero__intro">
          <h1 className="mod-shopHero__title">{t('helloBuyMuseumCard')}</h1>
          <p className="mod-shopHero__text">
            {t('theMuseumCardIsYourEntrance')} <br />
            <a
              href={t('moreInformationAboutHyperlink')}
              target="_blank"
              rel="noopener noreferrer"
              className="mod-shopHero__text moreInformationAbout"
            >
              {t('moreInformationAbout')} &#62;
            </a>
          </p>
        </div>
        <div className="atom-languageSelector" id="languageSelectMenu">
          <ul className="atom-languageSelector__choices">
            <li className="atom-languageSelector__singleChoice -selected">
              {/*in-hamun-style={i18n.language === Language.FINNISH}*/}
              <button
                onClick={() => changeLang(Language.FINNISH)}
                id={Language.FINNISH}
                className={
                  i18n.language === Language.FINNISH ? `select-language-button-selected` : `select-language-button`
                }
              >
                fi
              </button>
            </li>
            <li className="atom-languageSelector__singleChoice">
              <button
                onClick={() => changeLang(Language.ENGLISH)}
                id={Language.ENGLISH}
                className={
                  i18n.language === Language.ENGLISH ? `select-language-button-selected` : `select-language-button`
                }
              >
                en
              </button>
            </li>
            <li className="atom-languageSelector__singleChoice">
              <button
                onClick={() => changeLang(Language.SWEDISH)}
                id={Language.SWEDISH}
                className={
                  i18n.language === Language.SWEDISH ? `select-language-button-selected` : `select-language-button`
                }
              >
                sv
              </button>
            </li>
          </ul>
        </div>
        <div className="mod-shopHero__imageWrapper">
          <img className="mod-shopHero__image" src={illustrationBuy} alt="Osta Museokortti" />
        </div>
      </div>
    </Section>
  )
}

export default WelcomeSection

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  background-color: #2c253d;
  padding-bottom: 0;
  padding-top: 2.25rem;
  position: relative;

  @media (min-width: 1024px) {
    padding-top: 0;
  }

  &:before {
    width: 3000px;
    background-color: #2c253d;
    height: 5.625rem;
    transform: rotate(-2deg);
    position: absolute;
    left: -50%;
    bottom: -5rem;
    content: '';
  }

  .select-language-button {
    background-color: transparent;
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ffdbd8;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .select-language-button:focus {
    outline: 0;
  }

  .select-language-button:hover {
    color: #ff598f;
  }

  .select-language-button-selected:focus {
    outline: 0;
  }

  .select-language-button-selected {
    background-color: transparent;
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ff598f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }

  .mod-shopHero__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
    text-align: center;
  }
  @media (min-width: 1024px) {
    .mod-shopHero__contentWrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: 1.5rem;
      align-items: center;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopHero__intro {
      text-align: left;
      grid-column: 2 / span 5;
      grid-row: 1;
      margin-bottom: 3rem;
    }
  }
  .mod-shopHero__title {
    font-size: 2rem;
    line-height: 2.25rem;
    color: #ffdbd8;
    font-weight: 800;
  }
  @media (min-width: 768px) {
    .mod-shopHero__title {
      max-width: 25rem;
      margin: 0 auto;
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopHero__title {
      font-size: 3.5rem;
      line-height: 4rem;
      max-width: 30.625rem;
      margin: 0;
    }
  }
  .mod-shopHero__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0075rem;
    color: rgba(255, 219, 216, 0.8);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 17.875rem;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    .mod-shopHero__text {
      font-size: 1.125rem;
      line-height: 1.5rem;
      max-width: 23.25rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopHero__text {
      font-size: 1.25rem;
      line-height: 1.75rem;
      max-width: 30.625rem;
      margin-top: 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (min-width: 1200px) {
    .mod-shopHero__text {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 3.75rem;
    }
  }
  .mod-shopHero__imageWrapper {
    height: 13.125rem;
    width: auto;
    margin-top: 1.5rem;
  }
  @media (min-width: 768px) {
    .mod-shopHero__imageWrapper {
      height: 20rem;
      margin-top: 3rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopHero__imageWrapper {
      grid-column: 7 / span 5;
      grid-row: 1;
      height: 25rem;
      margin-top: 4.5rem;
    }
  }
  @media (min-width: 1200px) {
    .mod-shopHero__imageWrapper {
      height: 30rem;
    }
  }
  .mod-shopHero__image {
    max-width: 100%;
    height: 13.125rem;
    width: auto;
  }
  @media (min-width: 768px) {
    .mod-shopHero__image {
      height: 20rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopHero__image {
      height: 25rem;
    }
  }
  @media (min-width: 1200px) {
    .mod-shopHero__image {
      height: 30rem;
    }
  }
  .atom-languageSelector {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 1024px) {
    .atom-languageSelector {
      width: 100%;
      position: absolute;
      bottom: 2.5rem;
    }
  }
  @media (min-width: 1140px) {
    .atom-languageSelector {
      bottom: 3rem;
    }
  }
  .atom-languageSelector__choices {
    display: flex;
    justify-content: center;
  }
  .atom-languageSelector__singleChoice {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .atom-languageSelector__singleChoice:before {
    content: '/';
    position: absolute;
    left: -0.5rem;
    color: #ffdbd8;
  }
  .atom-languageSelector__singleChoice a {
    height: 2.5rem;
    width: 2.5rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ffdbd8;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .atom-languageSelector__singleChoice a:focus,
  .atom-languageSelector__singleChoice a:hover {
    color: #ff598f;
  }
  .atom-languageSelector__singleChoice.-selected a {
    color: #ff598f;
  }
  .atom-languageSelector__singleChoice:first-child:before {
    content: none;
  }
  .moreInformationAbout {
    color: #f94b84;
  }
`
