import Types from './actions/types'
import { ActionTypes } from './actions/creators'
import Price from '../../../Models/Price'

export const initialState: State = {
  is_fetching: false,
}

export default (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Types.GET_PRICES_REQUEST:
      return { ...initialState, is_fetching: true }
    case Types.GET_PRICES_SUCCESS:
      return { ...initialState, data: action.data }
    case Types.GET_PRICES_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export interface State {
  data?: Price
  is_fetching: boolean
  error?: any
}
