import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Checkbox from '../UiKit/Checkbox'
import Text from '../UiKit/Text'
import PaymentRequestContext from '../../Contexts/PaymentRequestContext'
import {
  createSetCouponCodeAction,
  createSetHasCouponCodeAction,
  createSetNewsletterAction,
  createSetTermsAndConditionsAction,
} from '../../Contexts/PaymentRequestContext/reducer'
import Input from '../UiKit/Input'
import colors from '../../constants/colors'

const CheckboxCondition: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    state: { data, errors },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onToggleTermsAndConditions = (): void => dispatch(createSetTermsAndConditionsAction(!data.terms_and_conditions))
  const onToggleNewsLetter = (): void => dispatch(createSetNewsletterAction(!data.newsletter))
  const onToggleHasCouponCode = (): void => dispatch(createSetHasCouponCodeAction(!data.has_coupon_code))
  const onChangeCouponCode = (value: string): void => dispatch(createSetCouponCodeAction(value))

  return (
    <Container>
      <Checkbox
        className="checkbox-distance"
        isChecked={!!data.terms_and_conditions}
        onToggle={onToggleTermsAndConditions}
        id="terms_and_conditions"
      >
        <Text className={errors && !!errors.terms_and_conditions ? 'text-decoration-error' : 'text-decoration'}>
          {t('conditionsOfOMuseumCard')}

          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration"
            href={'https://museot.fi/kayttoehdot'}
          >
            <Text className="hyperlink-text">{t('conditions')}</Text>
          </a>
        </Text>
      </Checkbox>
      <Checkbox
        className="checkbox-distance"
        isChecked={!!data.newsletter}
        id="newsletter"
        onToggle={onToggleNewsLetter}
      >
        <Text>{t('receiveTheNewsletter')}</Text>
      </Checkbox>
      <Checkbox
        className="checkbox-distance"
        id="has_coupon_code"
        isChecked={!!data.has_coupon_code}
        onToggle={onToggleHasCouponCode}
      >
        <Text>{t('iHaveCampaignCode')}</Text>
      </Checkbox>
      {data.has_coupon_code && (
        <Input
          placeholder={t('couponCode')}
          value={data.coupon_code || ''}
          onChange={onChangeCouponCode}
          errorMessage={errors?.coupon_code}
          id="couponCode"
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px 0;
  .checkbox-distance {
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    .checkbox-distance {
      margin: 0px 0;
      height: fit-content;
    }
  }
  .hyperlink-text {
    color: #fc3d7e;
    font-family: Montserrat-bold;
  }
  .text-decoration {
    text-decoration: none;
  }
  .text-decoration-error {
    color: ${colors.red};
    text-decoration: none;
  }
`

export default CheckboxCondition
