import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createGetReceiptRequestAction, createGetReceiptSuccessAction, createGetReceiptFailureAction } from './creators'
import ReceiptAPI from '../../../../api/ReceiptAPI'
import { getFilingCode } from '../../../../utils/storage'

export const getReceipt = (): ThunkAction<any, any, any, any> => async (dispatch: Dispatch): Promise<void> => {
  const filingCode = getFilingCode()
  if (filingCode) {
    dispatch(createGetReceiptRequestAction())
    try {
      const receiptAPI = new ReceiptAPI()
      const response = await receiptAPI.getReceipt(filingCode)
      dispatch(createGetReceiptSuccessAction(response.data))
    } catch (error) {
      dispatch(createGetReceiptFailureAction(error))
    }
  }
}
