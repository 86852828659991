import Types from './types'
import PaymentRequest from '../../../../../Models/PaymentRequest'
import PaymentResponse from '../../../../../Models/PaymentResponse'

export type ActionTypes =
  | { type: Types.POST_FORM_REQUEST; data: PaymentRequest }
  | { type: Types.POST_FORM_SUCCESS; data: PaymentResponse }
  | { type: Types.SET_PAYMENT_TYPE; value: string }
  | { type: Types.POST_FORM_FAILURE; error: any }

export const createPostFormRequestAction = (data: PaymentRequest): ActionTypes => ({
  type: Types.POST_FORM_REQUEST,
  data,
})
export const createPostFormSuccessAction = (data: PaymentResponse): ActionTypes => ({
  type: Types.POST_FORM_SUCCESS,
  data,
})
export const createPostFormFailureAction = (error: any): ActionTypes => ({
  type: Types.POST_FORM_FAILURE,
  error,
})

export const createSetPaymentType = (value: string): ActionTypes => ({
  type: Types.SET_PAYMENT_TYPE,
  value,
})
