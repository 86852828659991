import React from 'react'
import Input from '../Input'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { Portal } from 'react-overlays'
import dayjs from 'dayjs'

registerLocale('fi', fi)

interface Props {
  value?: string
  onChange: (date: string) => void
  errorMessage?: string
  id: string
}

const calculateMinTime = (): Date => {
  const date = new Date()

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours() + (date.getMinutes() < 50 ? 1 : 2)
  )
}

const getMinTime = (dateValue?: Date): Date => {
  const minTime = calculateMinTime()
  if (
    !dateValue ||
    (minTime.getFullYear() === dateValue.getFullYear() &&
      minTime.getMonth() === dateValue.getMonth() &&
      minTime.getDate() === dateValue.getDate())
  ) {
    return minTime
  }
  return new Date(1970, 1, 1, 0, 0)
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const DatePicker: React.FunctionComponent<Props> = ({ value, onChange, errorMessage, id }) => {
  const dateValue = value ? dayjs(value).toDate() : undefined

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const ExampleCustomInput = ({ value, onClick }): React.ReactNode => (
    <Input readOnly className="input-margin" onClick={onClick} value={value} id={id} />
  )
  return (
    <>
      <ReactDatePicker
        closeOnScroll={true}
        popperPlacement="bottom"
        popperContainer={CalendarContainer}
        disabledKeyboardNavigation
        selected={dateValue}
        onChange={(date): void => {
          if (date instanceof Date) {
            onChange(dayjs(date).format('YYYY-MM-DD HH:00:00'))
          }
        }}
        locale="fi"
        timeFormat="HH:mm"
        dateFormat="yyyy-MM-dd HH:mm"
        minDate={calculateMinTime()}
        minTime={getMinTime(dateValue)}
        maxTime={new Date(2030, 1, 1, 23, 59)}
        timeIntervals={60}
        showTimeSelect
        timeCaption="Aika"
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        customInput={<ExampleCustomInput />}
      />
      {errorMessage}
    </>
  )
}

export default DatePicker
