import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import addToBasket from '../../assets/images/addToBasket.png'
import giftCardIcon from '../../assets/images/giftCardIcon.png'
import facebook from '../../assets/images/Facebook_Black.png'
import instagram from '../../assets/images/Instagram_Black.png'
import twitter from '../../assets/images/Twitter_Black.png'
import BuyHeader from '../BuyHeader'
import Text, { TextType } from '../UiKit/Text'
import HyperLinkText from './HyperLinkText'
import { getPaymentType, getPaymentFormResponse, getActiveCoupon } from '../../utils/storage'
import TagManager from 'react-gtm-module'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { purchase } from '../../utils/tagManagerReport'

const socialMedia = [
  { icon: facebook, hyperlink: 'https://www.facebook.com/museokortti' },
  { icon: instagram, hyperlink: 'https://www.instagram.com/museokortti/' },
  { icon: twitter, hyperlink: 'https://twitter.com/museokortti' },
]

const mediaMapper = (t: TFunction): any =>
  socialMedia.map(item => (
    <a key={item.icon} target="_blank" rel="noopener noreferrer" href={item.hyperlink}>
      <img src={item.icon} alt={t('basket')} className="social-media-img" />
    </a>
  ))

const ThankYou: React.FunctionComponent<MappedState> = ({ receipts }) => {
  const { t } = useTranslation()
  const paymentType = getPaymentType()
  const paymentForm = getPaymentFormResponse()
  const promotionalCoupon = getActiveCoupon()

  useEffect(() => {
    if (!!receipts) {
      const tagManagerArgs = {
        dataLayer: {
          transactionId: paymentForm?.payment_reference,
          transactionAffiliation: '',
          transactionTotal: paymentType === 'personal' ? receipts?.price / 100 : receipts?.total / 100,
          transactionTax: 0.0,
          transactionProducts: {
            sku: `museokortti-${paymentType}`,
            name: 'museokortti',
            category: paymentType,
            promotionalCode: promotionalCoupon,
            price: receipts?.price / 100,
            quantity: paymentType === 'personal' ? 1 : receipts?.amount,
          },
        },
        dataLayerName: 'dataLayer',
      }
      const eventTagManager = {
        dataLayer: {
          event: paymentType === 'personal' ? 'cardBought' : 'cardGift',
        },
        dataLayerName: 'dataLayer',
      }
      TagManager.dataLayer(tagManagerArgs)
      TagManager.dataLayer(eventTagManager)
      purchase(receipts?.price, paymentForm?.payment_reference, promotionalCoupon || '')
    }
  }, [receipts, paymentForm, paymentType, promotionalCoupon])

  return (
    <Container>
      <div className="container">
        <BuyHeader
          imageSource={paymentType === 'gift' ? giftCardIcon : addToBasket}
          title={t('thankYouForYourOrder')}
        />
        <Text>{paymentType === 'gift' ? t('thankYouOrderGift') : t('yourPaymentHasBeenSuccessfullyCompleted')}</Text>
        {paymentType !== 'gift' && (
          <>
            <Text className="single-line-text line-break">{t('ifTheReceiptHasNotArrivedCheckYourMail')}</Text>
            <Text className="single-line-text line-break">{t('ifTheReceiptHasNotArrivedCheckYourMail2')}</Text>{' '}
          </>
        )}
        <div>{mediaMapper(t)}</div>
        <div className="standout-text">
          <Text className="single-line-text">{t('followMuseumCardFacebookInstagramTwitter')}</Text>
          <Text className="single-line-text" type={TextType.BOLD}>
            #museokortti #museokierros @museokortti
          </Text>
        </div>
        <a target="_blank" rel="noopener noreferrer" href={'https://museot.fi/sovellus'} className="text-decoration">
          <Text className="single-line-text" type={TextType.BOLD}>
            {t('downloadTheMuseumCardApp')}
          </Text>
        </a>
        <div className="standout-text">
          <Text className="single-line-text" type={TextType.BOLD}>
            {t('customerServiceLink').toUpperCase()}
          </Text>
          <HyperLinkText text="oftenAskedQuestions" hyperlink="https://museot.fi/vastauksia/" />
          <Text className="single-line-text">{t('phoneNumber')}</Text>
          <HyperLinkText text="email" hyperlink="mailto:museokortti@museot.fi" />
        </div>
      </div>
    </Container>
  )
}

interface MappedState {
  receipts: any
}
const mapStateToProps = (state: RootState): MappedState => ({
  receipts: state.webshop.receipt.data,
})

export default connect(mapStateToProps, undefined)(ThankYou)

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .single-line-text {
    display: block;
  }
  .social-media-img {
    margin: 10px;
    height: auto;
    width: 35px;
  }
  .standout-text {
    margin: 1rem 0;
  }
  .text-decoration {
    text-decoration: none;
  }
  .line-break {
    margin-top: 10px;
  }
`
