import Types from './types'
import PaymentSuccess from '../../../../../Models/PaymentSuccess'

export type ActionTypes =
  | { type: Types.PAYMENT_SUCCESS_REQUEST; data: any }
  | { type: Types.PAYMENT_SUCCESS_SUCCESS }
  | { type: Types.PAYMENT_SUCCESS_FAILURE; error: any }
  | { type: Types.CLEAR_ERROR_MESSAGE }

export const createPostPaymentSuccessRequestAction = (data: PaymentSuccess): ActionTypes => ({
  type: Types.PAYMENT_SUCCESS_REQUEST,
  data,
})
export const createPostPaymentSuccessSuccessAction = (): ActionTypes => ({
  type: Types.PAYMENT_SUCCESS_SUCCESS,
})
export const createPostPaymentSuccessFailureAction = (error: any): ActionTypes => ({
  type: Types.PAYMENT_SUCCESS_FAILURE,
  error,
})
export const createClearSuccessErrorMessage = (): ActionTypes => ({
  type: Types.CLEAR_ERROR_MESSAGE,
})
