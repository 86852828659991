import { combineReducers } from 'redux'
import form, { State as FormState, initialState as formInitialState } from './form'
import success, { State as SuccessState, initialState as successInitialState } from './success'
import update, { State as UpdateState, initialState as updateInitialState } from './update'

export interface State {
  form: FormState
  success: SuccessState
  update: UpdateState
}

export const initialState: State = {
  form: formInitialState,
  success: successInitialState,
  update: updateInitialState,
}

export default combineReducers({
  form,
  success,
  update,
})
