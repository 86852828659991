import React, { Dispatch, Reducer, ReducerAction, ReducerState } from 'react'
import BaseComponentState from '../../Models/BaseComponentState'
import PaymentRequest from '../../Models/PaymentRequest'
import { Action, initialState } from './reducer'

export type PaymentRequestReducerType = Reducer<BaseComponentState<PaymentRequest>, Action>
type PaymentRequestContextType = {
  state: ReducerState<PaymentRequestReducerType>
  dispatch: Dispatch<ReducerAction<PaymentRequestReducerType>>
}

const PaymentRequestContext = React.createContext<PaymentRequestContextType>({
  state: initialState,
  dispatch: () => undefined,
})

export default PaymentRequestContext
