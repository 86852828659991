import React from 'react'
import styled from 'styled-components'
import SelectBankBlock from './SelectBankBlock'
import master from '../../assets/images/bankLogos/master.gif'
import visa from '../../assets/images/bankLogos/visa.gif'

interface Props {
  onChange: (bankId: string) => void
}

const Solinor: React.FunctionComponent<Props> = ({ onChange }) => {
  return (
    <Container>
      <SelectBankBlock id={'visa'} imageSource={visa} toggleIsChecked={onChange.bind(undefined, 'visa')} />
      <SelectBankBlock
        id={'mastercard'}
        imageSource={master}
        toggleIsChecked={onChange.bind(undefined, 'mastercard')}
      />
      <div className="false-div" />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  .false-div {
    flex: 1;
    margin-right: 2rem;
  }
`

export default Solinor
