import React from 'react'
import styled from 'styled-components'
import SelectBankBlock from './SelectBankBlock'
import smartum from '../../assets/images/bankLogos/smartum.png'
import epassi from '../../assets/images/bankLogos/epassi.png'
import edenred from '../../assets/images/bankLogos/edenred.png'
import PaymentMethods from '../../Models/PaymentMethods'

interface Props {
  paymentMethod?: string
  onChange: (paymentMethod: PaymentMethods, id?: string | undefined) => void
}

const CulturalVoucher: React.FunctionComponent<Props> = ({ paymentMethod, onChange }) => {
  return (
    <Container>
      <div className="methods-row">
        <SelectBankBlock
          id="smartum"
          imageSource={smartum}
          toggleIsChecked={onChange.bind(undefined, PaymentMethods.SMARTUM)}
        />
        <SelectBankBlock
          id="epassi"
          imageSource={epassi}
          toggleIsChecked={onChange.bind(undefined, PaymentMethods.EPASSI)}
        />
        <SelectBankBlock
          id="edenred"
          imageSource={edenred}
          toggleIsChecked={onChange.bind(undefined, PaymentMethods.EDENRED)}
        />
      </div>
      {/* <div className="methods-row">
        <SelectBankBlock
          id="eazybreak"
          imageSource={eazybreak}
          toggleIsChecked={onChange.bind(undefined, PaymentMethods.EAZYBREAK)}
        />
        <div className="false-div" />
        <div className="false-div" />
      </div> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .methods-row {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;
  }
  .false-div {
    flex: 1;
    margin-right: 2rem;
  }
`

export default CulturalVoucher
