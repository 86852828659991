import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createPostFormFailureAction } from './creators'
import ReceiptAPI from '../../../../../api/ReceiptAPI'
import { redirectPaymentMethod } from './redirectPaymentMethod'

export const patchTransaction = (data: any, bankId: string | undefined): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: any
): Promise<void> => {
  try {
    const paymentInfo = getState().webshop.payment.form
    const newTransactionObject = { method: data }
    const peceiptAPI = new ReceiptAPI()
    const response = await peceiptAPI.patchTransaction(paymentInfo.data.transaction_id, newTransactionObject)
    if (response) {
      redirectPaymentMethod(data, paymentInfo.data.payment_forms, bankId)
    }
  } catch (error) {
    dispatch(createPostFormFailureAction(error))
  }
}
