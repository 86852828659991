import React from 'react'
import styled from 'styled-components'

interface Props {
  imageSource: string
  toggleIsChecked: () => void
  id: string
}

const SelectBankBlock: React.FunctionComponent<Props> = ({ imageSource, toggleIsChecked, id }: Props) => {
  return (
    <Container onClick={toggleIsChecked} id={id}>
      <div className="checkbox-text">
        <img src={imageSource} alt="buy" className="bank-logo" />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 2rem;
  .bank-logo {
    max-height: 37px;
    max-width: 4rem;
  }
  .checkbox-text {
    width: 4.5rem !important;
    cursor: pointer;
    margin: 0;
    padding: 0 1em 0 0;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
`

export default SelectBankBlock
