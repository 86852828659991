export const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'https://web.museo-staging.devolon.cloud/v1'
export const BASE_NEW_URL = process.env.REACT_APP_BASE_NEW_URL ?? 'https://web.museo-staging.devolon.cloud'
export const ENV = process.env.REACT_APP_ENV ?? 'development'
export const EXTEND_URL = process.env.REACT_APP_EXTEND_URL ?? 'http://asiakassivu.museot.neo.party/fi'
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL ?? 'http://localhost:3000'
export const TAG = process.env.REACT_APP_ENV === 'production' ? 'GTM-NXS2WBM' : 'GTM-TFCWP7S'

export const HeaderFooterSRC =
  process.env.REACT_APP_ENV === 'production'
    ? {
        header: `${BASE_NEW_URL}/web/web-pages/header`,
        footer: `${BASE_NEW_URL}/web/web-pages/footer`,
        headerStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_header.css',
        footerStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_footer.css',
        pageStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/defer.js',
        mobiilinaviStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/mobiilinavi.js',
      }
    : {
        header: `${BASE_NEW_URL}/web/web-pages/header`,
        footer: `${BASE_NEW_URL}/web/web-pages/footer`,
        headerStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_header.css',
        footerStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_footer.css',
        pageStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/defer.js',
        mobiilinaviStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/mobiilinavi.js',
      }
