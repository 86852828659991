export const returnErrorMessageContent = (data: any): any => {
  if (!!data) {
    return Object.keys(data).map(val => {
      if (typeof val === 'string') return data[val] + `\n`
      else return data[val][0] + `\n`
    })
  } else return ''
}

export const formatPrice = (value: number, hasSign = true): string =>
  (value % 1 === 0 ? `${value}` : `${value.toFixed(2).replace('.', ',')}`) + `${hasSign ? ' €' : ''}`

export const renderLang = () => localStorage.getItem('i18nextLng') || 'fi'

export const htmlEntityDecode = (str: string): string => {
  if (typeof str === 'string') {
    str = str.replace('&nbsp;', ' ')
    str = str.replace(/\\n/g, '  ')
    str = str.replace(/<br>/g, '\n')
    str = str.replace(/&quot;/g, "''")
    str = str.replace(/&#228;/g, 'ä')
    str = str.replace(/&#226;/g, 'ö')
    str = str.replace(/&rdquo;/g, '"')
    str = str.replace(/&ndash;/g, '-')
    str = str.replace(//g, '€')
    str = str.replace(/&gt;/g, '>')
    str = str.replace('', '')
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec)
    })
  }
  return str
}
