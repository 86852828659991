import styled from 'styled-components'

const DoubleInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 7px 0;
  > :nth-child(1) {
    flex: 3;
  }
  > :nth-child(2) {
    flex: 4;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
  }
`

export default DoubleInputContainer
