import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Question from './Question'
import Input from '../../../UiKit/Input'
import styled from 'styled-components'
import DoubleInputContainer from '../../../UiKit/Input/DoubleInputContiner'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'
import {
  createSetGiftInfoReceiverEmailAction,
  createSetGiftInfoReceiverNameAction,
  createSetGiftInfoReceiverPhoneAction,
} from '../../../../Contexts/PaymentRequestContext/reducer'
import PhoneInput from '../../../UiKit/PhoneInput'

interface Props {
  index: number
}

const GiftReceiverSingleForm: React.FunctionComponent<Props> = ({ index }) => {
  const { t } = useTranslation()
  const {
    state: { data, errors },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onChangeName = (value: string): void => dispatch(createSetGiftInfoReceiverNameAction(index, value))
  const onChangeEmail = (value: string): void => dispatch(createSetGiftInfoReceiverEmailAction(index, value))
  const onChangePhone = (value: string): void => dispatch(createSetGiftInfoReceiverPhoneAction(index, value))

  const giftInfo = data.gift_info && data.gift_info[index]
  const giftInfoError = errors?.gift_info && errors.gift_info[index]
  if (!giftInfo) {
    return null
  }

  return (
    <Container>
      <Question
        title={t('fillInContactInformationOfGiftReceiver')}
        description={t('giftReceiver', { index: index + 1 })}
      >
        <Input
          placeholder={t('requiredName')}
          className="input-margin"
          value={giftInfo.receiver_name}
          errorMessage={giftInfoError?.receiver_name}
          onChange={onChangeName}
          id={'requiredName'}
        />
        <DoubleInputContainer>
          <Input
            placeholder={t('requiredEmailAddress')}
            value={giftInfo.receiver_email}
            errorMessage={giftInfoError?.receiver_email}
            onChange={onChangeEmail}
            id={'requiredEmailAddress'}
          />
          <PhoneInput
            placeholder={t('requiredPhone') + '*'}
            value={giftInfo.receiver_phone || ''}
            errorMessage={giftInfoError?.receiver_phone}
            onChange={onChangePhone}
            id={'requiredPhone'}
          />
        </DoubleInputContainer>
      </Question>
    </Container>
  )
}

const Container = styled.div`
  .input-margin {
    margin: 10px 5px;
  }
`

export default GiftReceiverSingleForm
