import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import BuyHeader from '../BuyHeader'
import { useTranslation } from 'react-i18next'
import Text, { TextType } from '../UiKit/Text'
import IndividualForm from './IndividualForm'
import Button from '../UiKit/Button'
import CheckboxCondition from './CheckboxConditions'
import ReturnButton from '../UiKit/Button/ReturnButton'
import StepsDisplay from '../Buy/StepsDisplay'
import addToBasket from '../../assets/images/addToBasket.png'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { getPrices } from '../../store/webshop/price/actions/getPrices'
import PaymentRequestContext from '../../Contexts/PaymentRequestContext'
import { validatePersonal } from '../../Contexts/PaymentRequestContext/reducer/validate'
import PaymentRequest from '../../Models/PaymentRequest'
import { postForm } from '../../store/webshop/payment/form/actions/postForm'
import ClipLoader from 'react-spinners/ClipLoader'
import colors from '../../constants/colors'
import ErrorMessage from '../UiKit/ErrorMessage'
import { formatPrice, returnErrorMessageContent } from '../../utils'

interface Props extends MappedState, MappedDispatch {}

const PersonalInfo: React.FunctionComponent<Props> = ({ price, getPrice, postForm, isFetching, error }) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    getPrice()
  }, [getPrice])

  const {
    state: { data },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onClickContinue = (): void => {
    if (validatePersonal(data, dispatch, t) && !!data.terms_and_conditions) {
      postForm(data, i18n.language)
    }
  }

  return (
    <Container>
      <div className="container">
        <div className="return-button">
          <ReturnButton />
        </div>

        <BuyHeader imageSource={addToBasket} title={t('fillInPersonalInformation')} />
        <div className="description text-display">
          <Text type={TextType.BIG_BOLD} className="bold-text">
            {t('youCanUseMuseumCardImmediately')}
          </Text>
          <Text type={TextType.REGULAR} className="paragraph">
            {t('youCanUseMuseumCardImmediatelyDescription')}
          </Text>
          <Text type={TextType.REGULAR} className="paragraph">
            {t('youCanUseMuseumCardImmediatelyDescription2')}
          </Text>
          <br />
          <Text type={TextType.BIG_BOLD} className="bold-text">
            {t('noteIfYou1')}
          </Text>
          <br />
          <Text type={TextType.REGULAR} className="note-text">
            {t('noteIfYou2')}
          </Text>
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={'https://asiakassivu.museokortti.fi/fi'}
            className="text-decoration"
          >
            <Text className="hyperlink-text font-size" type={TextType.BOLD}>
              {t('ownMuseumCardPage')}
            </Text>
          </a>
          <Text className="font-size">{t('orAtAMuseum')}</Text>
        </div>
        <Text type={TextType.REGULAR} className="paragraph">
          {t('thePriceOfMuseumCardIs')} {price && <Text type={TextType.BOLD}>{formatPrice(price / 100)}</Text>}
        </Text>
        {/* <Text className="bold-text">
          <Text type={TextType.BIG_BOLD} className="bold-text">
            {t('campaignBoldText')}
          </Text>
          <div dangerouslySetInnerHTML={{ __html: t('campaignText') }} />
        </Text> */}
        {!!error && (
          <ErrorMessage>
            <Text>{returnErrorMessageContent(error)[0]}</Text>
          </ErrorMessage>
        )}
        <StepsDisplay totalSteps={2} currentStep={1} />
        <IndividualForm />
        <CheckboxCondition />
        {isFetching && <ClipLoader size={20} color={colors.primary} />}
        <Button onClick={onClickContinue}>{t('continue')}</Button>
        <div className="return-button">
          <ReturnButton />
        </div>
      </div>
    </Container>
  )
}

interface MappedState {
  isFetching: boolean
  price?: number
  error: any
}

interface MappedDispatch {
  getPrice: () => void
  postForm: (data: PaymentRequest, language: string) => void
}

const mapStateToProps = (state: RootState): MappedState => ({
  isFetching:
    state.webshop.price.is_fetching || state.webshop.price.is_fetching || state.webshop.payment.form.is_fetching,
  price: state.webshop.price.data && state.webshop.price.data.card,
  error: state.webshop.payment.form.error,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getPrice: (): any => dispatch(getPrices()),
  postForm: (data: PaymentRequest, language: string): any => dispatch(postForm(data, language)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .description {
    margin: 20px 0;
  }
  .text-display {
    display: block;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
  .paragraph {
    font-size: 16px;
    margin-bottom: 20px;
    display: inline-block;
    line-height: 21px;
  }
  .bold-text {
    font-size: 16px;
    display: inline-block;
    line-height: 21px;
  }
  .text-decoration {
    text-decoration: none;
  }
  .hyperlink-text {
    color: #fc3d7e;
    font-family: Montserrat-bold;
  }
  .note-text {
    font-size: 16px;
    display: inline-block;
    line-height: 21px;
  }
  .font-size {
    font-size: 12pt;
  }
`
