import React from 'react'
import InputContainer from './InputContainer'

interface Props {
  value?: string
  errorMessage?: string
  placeholder?: string
  onChange?: (value: string) => void
  onClick?: () => void
  errorClassName?: string
  inputClassName?: string
  className?: string
  type?: string
  readOnly?: boolean
  id: string
}

const Input: React.FunctionComponent<Props> = ({
  value,
  errorMessage,
  placeholder,
  onChange,
  onClick,
  errorClassName,
  className,
  inputClassName,
  type,
  id,
  readOnly,
}) => {
  return (
    <InputContainer errorMessage={errorMessage} errorClassName={errorClassName} className={className}>
      <input
        readOnly={readOnly}
        type={type}
        className={inputClassName}
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        onChange={(e): void => onChange && onChange(e.target.value)}
        id={id}
      />
    </InputContainer>
  )
}

export default Input
