import PaymentMethods from '../../../../../Models/PaymentMethods'
import redirectPost from '../../../../../utils/redirectPost'

export const redirectPaymentMethod = (paymentMethod, paymentForms, bankId): void => {
  switch (paymentMethod) {
    case PaymentMethods.PAYTRAIL:
      const paytrailObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.PAYTRAIL)
      )[0]
      if (paytrailObject && paytrailObject.paytrail && bankId) {
        redirectPost(paytrailObject.paytrail.methods[bankId].action_url, {
          ...paytrailObject.paytrail.methods[bankId].fields,
        })
      }
      break
    case PaymentMethods.SMARTUM:
      const smartumObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.SMARTUM)
      )[0]
      if (smartumObject && smartumObject.smartum) {
        window.location.href = smartumObject.smartum
      }
      break
    case PaymentMethods.SOLINOR:
      const solinorObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.SOLINOR)
      )[0]
      if (solinorObject && solinorObject.solinor && bankId) {
        redirectPost(solinorObject.solinor.action, solinorObject.solinor.fields)
      }
      break
    case PaymentMethods.EPASSI:
      const epassiObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.EPASSI)
      )[0]
      if (epassiObject && epassiObject.epassi) {
        redirectPost(epassiObject.epassi.action_url, epassiObject.epassi.fields)
      }
      break
    case PaymentMethods.EDENRED:
      const edenredObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.EDENRED)
      )[0]
      if (edenredObject && edenredObject.edenred) {
        redirectPost(edenredObject.edenred.action, {
          ...edenredObject.edenred.fields,
        })
      }
      break
    case PaymentMethods.EAZYBREAK:
      const eazybreakObject = paymentForms.filter(paymentForm =>
        Object.keys(paymentForm).includes(PaymentMethods.EAZYBREAK)
      )[0]
      if (eazybreakObject && eazybreakObject.eazybreak) {
        redirectPost(eazybreakObject.eazybreak.action_url, eazybreakObject.eazybreak.fields)
      }
      break
  }
}
