import PaymentResponse from '../Models/PaymentResponse'

/** TODo: put your storage functionality here to read/write from storage here **/
export const getToken = (): string => 'token'

const PAYMENT_FORM_KEY = 'payment-form'
const PAYMENT_TYPE = 'payment-type'
const FILLING_TYPE = 'filling-type'
const FILLING_COUPON = 'filling-coupon'

export const setPaymentFormResponse = (data: PaymentResponse): void =>
  localStorage.setItem(PAYMENT_FORM_KEY, JSON.stringify(data))

export const getPaymentFormResponse = (): PaymentResponse | undefined => {
  const localStorageValue = localStorage.getItem(PAYMENT_FORM_KEY)
  return localStorageValue && JSON.parse(localStorageValue)
}

export const removePaymentFormResponse = (): void => localStorage.removeItem(PAYMENT_FORM_KEY)

export const calculateStringMinTime = (): string => {
  const date = new Date()
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours() + (date.getMinutes() < 50 ? 1 : 2)
  ).toString()
}

export const setPaymentType = (value: string): void => localStorage.setItem(PAYMENT_TYPE, JSON.stringify(value))

export const getPaymentType = (): string | undefined => {
  const localStorageValue = localStorage.getItem(PAYMENT_TYPE)
  return localStorageValue && JSON.parse(localStorageValue)
}
export const setFilingCode = (value: string): void => localStorage.setItem(FILLING_TYPE, JSON.stringify(value))

export const getFilingCode = (): string | undefined => {
  const localStorageValue = localStorage.getItem(FILLING_TYPE)
  return localStorageValue && JSON.parse(localStorageValue)
}

export const setActiveCoupon = (value: string): void => localStorage.setItem(FILLING_COUPON, JSON.stringify(value))
export const getActiveCoupon = (): string | undefined => {
  const localStorageValue = localStorage.getItem(FILLING_COUPON)
  return localStorageValue && JSON.parse(localStorageValue)
}
