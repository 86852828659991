import React from 'react'
import styled from 'styled-components'
import illoOstaLaskulla from '../../assets/images/homePage/illo-osta-laskulla.svg'
import { useTranslation } from 'react-i18next'

const CardForCompany: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <div className="mod-imageAndText__contentWrapper">
        <div className="mod-imageAndText__imageWrapper">
          <img className="mod-imageAndText__image" src={illoOstaLaskulla} alt="Osta Museokortti laskulla" />
        </div>
        <div className="mod-imageAndText__textWrapper">
          <h1 className="mod-imageAndText__title">Museokortti yrityksellesi</h1>
          <p className="mod-imageAndText__text">{t('companiesAndCommunities')}</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://yritystilaus.museokortti.fi/fi"
            className="button mod-imageAndText__button"
          >
            <span>{t('fillInOrderForm')}</span>
          </a>
        </div>
      </div>
    </Section>
  )
}

export default CardForCompany

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  .button,
  .mod-shopOptions__submitButton {
    text-decoration: none;
    background-color: #ff598f;
    outline: 0;
    border: 0 none;
    display: flex;
    -webkit-appearance: none;
    appearance: none;
    height: auto;
    font-size: 0.875rem;
    line-height: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding-left: 1.5rem;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    padding-right: 1.5rem;
    border-radius: 3rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    transition: box-shadow 0.25s ease, background-color 0.25s ease;
  }
  .button span,
  .mod-shopOptions__submitButton span {
    margin-bottom: 0.25rem;
    color: white;
  }
  .button:hover,
  .button:focus,
  .mod-shopOptions__submitButton:hover,
  .mod-shopOptions__submitButton:focus {
    box-shadow: 0 24px 24px -16px rgba(44, 37, 61, 0.2);
    background-color: #ff407e;
  }
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  background-color: #2c253d;
  padding-top: 2.25rem;
  padding-bottom: 9rem;
  position: relative;
  @media (min-width: 1024px) {
    padding-bottom: 12.5rem;
  }
  @media (min-width: 1024px) {
    padding-top: 0;
  }
  @media (min-width: 1200px) {
    padding-top: 3rem;
    padding-bottom: 15rem;
  }
  &:before {
    width: 3000px;
    background-color: #2c253d;
    height: 8.75rem;
    transform: rotate(-2deg);
    position: absolute;
    top: -5.625rem;
    left: -50%;
    content: '';
  }

  .mod-imageAndText__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
  }
  @media (min-width: 768px) {
    .mod-imageAndText__contentWrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: 1.5rem;
    }
  }
  .mod-imageAndText__title {
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    font-size: 2rem;
    line-height: 2.25rem;
    color: #ffdbd8;
  }
  @media (min-width: 1024px) {
    .mod-imageAndText__title {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  .mod-imageAndText__text {
    color: rgba(255, 219, 216, 0.8);
    margin-top: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @media (min-width: 1024px) {
    .mod-imageAndText__text {
      margin-top: 2rem;
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
  .mod-imageAndText__button {
    margin-top: 2.5rem;
  }
  .mod-imageAndText__imageWrapper {
    height: 17.5rem;
    width: auto;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  @media (min-width: 768px) {
    .mod-imageAndText__imageWrapper {
      grid-column: 1 / span 6;
    }
  }
  @media (min-width: 1024px) {
    .mod-imageAndText__imageWrapper {
      grid-column: 2 / span 5;
    }
  }
  .mod-imageAndText__textWrapper {
    max-width: 26.25rem;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .mod-imageAndText__textWrapper {
      grid-column: 7 / span 5;
    }
  }
  @media (min-width: 1024px) {
    .mod-imageAndText__textWrapper {
      grid-column: 7 / span 5;
    }
  }
  @media (min-width: 1140px) {
    .mod-imageAndText__textWrapper {
      grid-column: 7 / span 4;
    }
  }
  .mod-imageAndText__image {
    max-width: 100%;
    height: 17.5rem;
    width: auto;
  }
  @media (min-width: 768px) {
    .mod-imageAndText__image {
      height: 25rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-imageAndText__image {
      height: 33.75rem;
      margin-top: -1.875rem;
    }
  }
`
