/* eslint @typescript-eslint/camelcase: 0 */
import { Reducer } from 'react'
import { manipulateData, manipulateError } from '../../../utils/reducerManipulationHelper'
import BaseComponentState from '../../../Models/BaseComponentState'
import PaymentRequest from '../../../Models/PaymentRequest'
import DeepPartial from '../../../Models/DeepPartial'
import Language from '../../../Models/Language'
import ShoppingType from '../../../Models/ShoppingType'
import GiftType from '../../../Models/GiftType'
import GiftInfo from '../../../Models/GiftInfo'
import DigitalGiftType from '../../../Models/DigitalGiftType'
import { calculateStringMinTime } from '../../../utils/storage'
import { ENV } from '../../../constants/env'
import dayjs from 'dayjs'

type Errors = DeepPartial<PaymentRequest>

export enum ActionTypes {
  SET_SHOPPING_TYPE = 'SET_SHOPPING_TYPE',
  SET_AMOUNT = 'SET_AMOUNT',
  SET_CUSTOMER_FIRST_NAME = 'SET_CUSTOMER_FIRST_NAME',
  SET_CUSTOMER_LAST_NAME = 'SET_CUSTOMER_LAST_NAME',
  SET_CUSTOMER_PASSWORD = 'SET_CUSTOMER_PASSWORD',
  SET_CUSTOMER_CONFIRM_PASSWORD = 'SET_CUSTOMER_CONFIRM_PASSWORD',
  SET_CUSTOMER_DATE_OF_BIRTH = 'SET_CUSTOMER_DATE_OF_BIRTH',
  SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS',
  SET_CUSTOMER_ZIPCODE = 'SET_CUSTOMER_ZIPCODE',
  SET_CUSTOMER_CITY = 'SET_CUSTOMER_CITY',
  SET_CUSTOMER_PHONE = 'SET_CUSTOMER_PHONE',
  SET_CUSTOMER_EMAIL = 'SET_CUSTOMER_EMAIL',
  SET_COUPON_CODE = 'SET_COUPON_CODE',
  SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS',
  SET_NEWSLETTER = 'SET_NEWSLETTER',
  SET_HAS_COUPON_CODE = 'SET_HAS_COUPON_CODE',
  SET_GIFT_TYPE = 'SET_GIFT_TYPE',
  SET_GIFT_BUYER_NAME = 'SET_GIFT_BUYER_NAME',
  SET_GIFT_BUYER_EMAIL = 'SET_GIFT_BUYER_EMAIL',
  SET_GIFT_BUYER_PHONE = 'SET_GIFT_BUYER_PHONE',
  SET_GIFT_GREETINGS_MESSAGE = 'SET_GIFT_GREETINGS_MESSAGE',
  SET_GIFT_RECEIVER_FIRST_NAME = 'SET_GIFT_RECEIVER_FIRST_NAME',
  SET_GIFT_RECEIVER_ADDRESS = 'SET_GIFT_RECEIVER_ADDRESS',
  SET_GIFT_RECEIVER_ZIP_CODE = 'SET_GIFT_RECEIVER_ZIP_CODE',
  SET_GIFT_RECEIVER_CITY = 'SET_GIFT_RECEIVER_CITY',
  SET_GIFT_RECEIVER_ORGANISATION = 'SET_GIFT_RECEIVER_ORGANISATION',
  SET_GIFT_RECEIVER_LAST_NAME = 'SET_GIFT_RECEIVER_LAST_NAME',
  INCREMENT_DIGITAL_GIFT_AMOUNT = 'INCREMENT_DIGITAL_GIFT_AMOUNT',
  DECREMENT_DIGITAL_GIFT_AMOUNT = 'DECREMENT_DIGITAL_GIFT_AMOUNT',
  INCREMENT_LETTER_GIFT_AMOUNT = 'INCREMENT_LETTER_GIFT_AMOUNT',
  DECREMENT_LETTER_GIFT_AMOUNT = 'DECREMENT_LETTER_GIFT_AMOUNT',
  SET_GIFT_INFO_RECEIVER_EMAIL = 'SET_GIFT_INFO_RECEIVER_EMAIL',
  SET_GIFT_INFO_RECEIVER_NAME = 'SET_GIFT_INFO_RECEIVER_NAME',
  SET_GIFT_INFO_RECEIVER_PHONE = 'SET_GIFT_INFO_RECEIVER_PHONE',
  SET_DIGITAL_GIFT_TYPE = 'SET_DIGITAL_GIFT_TYPE',
  SET_GIFT_SET_AT = 'SET_GIFT_SET_AT',
  SET_GIFT_INFO = 'SET_GIFT_INFO',
  SET_ERRORS = 'SET_ERRORS',
  RESET = 'RESET',
}

export type Action =
  | { type: ActionTypes.SET_SHOPPING_TYPE; shopping_type: ShoppingType }
  | { type: ActionTypes.SET_CUSTOMER_FIRST_NAME; customer_first_name: string }
  | { type: ActionTypes.SET_CUSTOMER_LAST_NAME; customer_last_name: string }
  | { type: ActionTypes.SET_CUSTOMER_PASSWORD; password: string }
  | { type: ActionTypes.SET_CUSTOMER_CONFIRM_PASSWORD; password_confirmation: string }
  | { type: ActionTypes.SET_CUSTOMER_DATE_OF_BIRTH; customer_date_of_birth: string }
  | { type: ActionTypes.SET_CUSTOMER_ADDRESS; customer_address: string }
  | { type: ActionTypes.SET_CUSTOMER_ZIPCODE; customer_zipcode: string }
  | { type: ActionTypes.SET_CUSTOMER_CITY; customer_city: string }
  | { type: ActionTypes.SET_CUSTOMER_PHONE; customer_phone?: string }
  | { type: ActionTypes.SET_CUSTOMER_EMAIL; customer_email: string }
  | { type: ActionTypes.SET_COUPON_CODE; coupon_code?: string }
  | { type: ActionTypes.SET_TERMS_AND_CONDITIONS; terms_and_condition: boolean }
  | { type: ActionTypes.SET_HAS_COUPON_CODE; has_coupon_code: boolean }
  | { type: ActionTypes.SET_NEWSLETTER; newsletter: boolean }
  | { type: ActionTypes.SET_GIFT_TYPE; gift_type: string }
  | { type: ActionTypes.SET_AMOUNT; amount: number }
  | { type: ActionTypes.SET_GIFT_BUYER_NAME; gift_buyer_name: string }
  | { type: ActionTypes.SET_GIFT_BUYER_EMAIL; gift_buyer_email: string }
  | { type: ActionTypes.SET_GIFT_BUYER_PHONE; gift_buyer_phone: string }
  | { type: ActionTypes.SET_GIFT_GREETINGS_MESSAGE; gift_greetings_message: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_FIRST_NAME; gift_receiver_first_name: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_LAST_NAME; gift_receiver_last_name: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_ADDRESS; gift_receiver_address: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_ZIP_CODE; gift_receiver_zip_code: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_CITY; gift_receiver_city: string }
  | { type: ActionTypes.SET_GIFT_RECEIVER_ORGANISATION; gift_receiver_organisation: string }
  | { type: ActionTypes.INCREMENT_DIGITAL_GIFT_AMOUNT }
  | { type: ActionTypes.DECREMENT_DIGITAL_GIFT_AMOUNT }
  | { type: ActionTypes.INCREMENT_LETTER_GIFT_AMOUNT }
  | { type: ActionTypes.DECREMENT_LETTER_GIFT_AMOUNT }
  | { type: ActionTypes.SET_GIFT_SET_AT; gift_send_at: string }
  | { type: ActionTypes.SET_GIFT_INFO_RECEIVER_EMAIL; index: number; receiver_email: string }
  | { type: ActionTypes.SET_GIFT_INFO_RECEIVER_NAME; index: number; receiver_name: string }
  | { type: ActionTypes.SET_GIFT_INFO_RECEIVER_PHONE; index: number; receiver_phone: string }
  | { type: ActionTypes.SET_DIGITAL_GIFT_TYPE; digital_gift_type: string }
  | { type: ActionTypes.SET_GIFT_INFO; gift_info: GiftInfo[] }
  | { type: ActionTypes.SET_ERRORS; errors: Errors }
  | { type: ActionTypes.RESET; data?: Partial<BaseComponentState<PaymentRequest>> }

export const createSetShoppingTypeAction = (shopping_type: ShoppingType): Action => ({
  type: ActionTypes.SET_SHOPPING_TYPE,
  shopping_type,
})

export const createSetAmountAction = (amount: number): Action => ({
  type: ActionTypes.SET_AMOUNT,
  amount,
})

export const createSetCustomerFirstNameAction = (customer_first_name: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_FIRST_NAME,
  customer_first_name,
})
export const createSetCustomerLastNameAction = (customer_last_name: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_LAST_NAME,
  customer_last_name,
})
export const createSetCustomerPasswordAction = (password: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_PASSWORD,
  password,
})
export const createSetCustomerConfirmPasswordAction = (password_confirmation: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_CONFIRM_PASSWORD,
  password_confirmation,
})
export const createSetCustomerDateOfBirthAction = (customer_date_of_birth: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_DATE_OF_BIRTH,
  customer_date_of_birth,
})
export const createSetCustomerAddressAction = (customer_address: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_ADDRESS,
  customer_address,
})
export const createSetCustomerZipcodeAction = (customer_zipcode: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_ZIPCODE,
  customer_zipcode,
})
export const createSetCustomerCityAction = (customer_city: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_CITY,
  customer_city,
})
export const createSetCustomerPhoneAction = (customer_phone: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_PHONE,
  customer_phone,
})
export const createSetCustomerEmailAction = (customer_email: string): Action => ({
  type: ActionTypes.SET_CUSTOMER_EMAIL,
  customer_email,
})
export const createSetCouponCodeAction = (coupon_code: string): Action => ({
  type: ActionTypes.SET_COUPON_CODE,
  coupon_code,
})
export const createSetTermsAndConditionsAction = (terms_and_condition: boolean): Action => ({
  type: ActionTypes.SET_TERMS_AND_CONDITIONS,
  terms_and_condition,
})
export const createSetNewsletterAction = (newsletter: boolean): Action => ({
  type: ActionTypes.SET_NEWSLETTER,
  newsletter,
})
export const createSetHasCouponCodeAction = (has_coupon_code: boolean): Action => ({
  type: ActionTypes.SET_HAS_COUPON_CODE,
  has_coupon_code,
})
export const createSetGiftTypeAction = (gift_type: string): Action => ({
  type: ActionTypes.SET_GIFT_TYPE,
  gift_type,
})
export const createSetGiftBuyerNameAction = (gift_buyer_name: string): Action => ({
  type: ActionTypes.SET_GIFT_BUYER_NAME,
  gift_buyer_name,
})
export const createSetGiftBuyerEmailAction = (gift_buyer_email: string): Action => ({
  type: ActionTypes.SET_GIFT_BUYER_EMAIL,
  gift_buyer_email,
})
export const createSetGiftGreetingMessageAction = (gift_greetings_message: string): Action => ({
  type: ActionTypes.SET_GIFT_GREETINGS_MESSAGE,
  gift_greetings_message,
})
export const createSetGiftReceiverFirstNameAction = (gift_receiver_first_name: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_FIRST_NAME,
  gift_receiver_first_name,
})
export const createSetGiftReceiverAddressAction = (gift_receiver_address: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_ADDRESS,
  gift_receiver_address,
})
export const createSetGiftReceiverZipCodeAction = (gift_receiver_zip_code: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_ZIP_CODE,
  gift_receiver_zip_code,
})
export const createSetGiftReceiverCityAction = (gift_receiver_city: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_CITY,
  gift_receiver_city,
})
export const createSetGiftReceiverLastNameAction = (gift_receiver_last_name: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_LAST_NAME,
  gift_receiver_last_name,
})
export const createSetGiftBuyerPhoneAction = (gift_buyer_phone: string): Action => ({
  type: ActionTypes.SET_GIFT_BUYER_PHONE,
  gift_buyer_phone,
})
export const createSetGiftReceiverOrganisationAction = (gift_receiver_organisation: string): Action => ({
  type: ActionTypes.SET_GIFT_RECEIVER_ORGANISATION,
  gift_receiver_organisation,
})
export const createSetGiftSetAtAction = (gift_send_at: string): Action => ({
  type: ActionTypes.SET_GIFT_SET_AT,
  gift_send_at,
})
export const createSetDigitalGiftTypeAction = (digital_gift_type: string): Action => ({
  type: ActionTypes.SET_DIGITAL_GIFT_TYPE,
  digital_gift_type,
})
export const createSetGiftInfoAction = (gift_info: GiftInfo[]): Action => ({
  type: ActionTypes.SET_GIFT_INFO,
  gift_info,
})
export const createIncrementDigitalGiftAction = (): Action => ({ type: ActionTypes.INCREMENT_DIGITAL_GIFT_AMOUNT })
export const createDecrementDigitalGiftAction = (): Action => ({ type: ActionTypes.DECREMENT_DIGITAL_GIFT_AMOUNT })
export const createIncrementLetterGiftAction = (): Action => ({ type: ActionTypes.INCREMENT_LETTER_GIFT_AMOUNT })
export const createDecrementLetterGiftAction = (): Action => ({ type: ActionTypes.DECREMENT_LETTER_GIFT_AMOUNT })
export const createSetErrorsAction = (errors: Errors): Action => ({ type: ActionTypes.SET_ERRORS, errors })
export const createSetGiftInfoReceiverEmailAction = (index: number, receiver_email: string): Action => ({
  type: ActionTypes.SET_GIFT_INFO_RECEIVER_EMAIL,
  index,
  receiver_email,
})
export const createSetGiftInfoReceiverNameAction = (index: number, receiver_name: string): Action => ({
  type: ActionTypes.SET_GIFT_INFO_RECEIVER_NAME,
  index,
  receiver_name,
})
export const createSetGiftInfoReceiverPhoneAction = (index: number, receiver_phone: string): Action => ({
  type: ActionTypes.SET_GIFT_INFO_RECEIVER_PHONE,
  index,
  receiver_phone,
})
export const createResetAction = (data?: Partial<BaseComponentState<PaymentRequest>>): Action => ({
  type: ActionTypes.RESET,
  data,
})

export const initialState: BaseComponentState<PaymentRequest> = {
  data: {
    type: ShoppingType.PERSONAL,
    source: 'web_shop',
    amount: 1,
    contact_lang: Language.FINNISH,
    newsletter: false,
    environment: ENV === 'development' ? 'staging' : 'production',
  },
  errors: undefined,
}

const createEmptyGiftInfoArray = (amount: number): GiftInfo[] => {
  return Array.from(Array(amount)).map(
    (): GiftInfo => ({
      receiver_name: '',
      receiver_email: '',
      receiver_phone: '',
    })
  )
}

const reducer: Reducer<BaseComponentState<PaymentRequest>, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_SHOPPING_TYPE:
      return manipulateData<PaymentRequest>(state, {
        type: action.shopping_type,
        gift_type: action.shopping_type === ShoppingType.PERSONAL ? undefined : GiftType.DIGITAL,
        newsletter: action.shopping_type === ShoppingType.PERSONAL ? false : undefined,
        gift_info: action.shopping_type === ShoppingType.PERSONAL ? undefined : createEmptyGiftInfoArray(1),
        digital_gift_type: action.shopping_type === ShoppingType.PERSONAL ? undefined : DigitalGiftType.NORMAL,
        plastic_card: true,
        gift_send_at:
          action.shopping_type === ShoppingType.PERSONAL
            ? undefined
            : dayjs(calculateStringMinTime()).format('YYYY-MM-DD HH:00:00'),
      })
    case ActionTypes.SET_AMOUNT:
      return manipulateData<PaymentRequest>(state, { amount: action.amount })
    case ActionTypes.SET_CUSTOMER_FIRST_NAME:
      return manipulateData<PaymentRequest>(state, { customer_first_name: action.customer_first_name })
    case ActionTypes.SET_CUSTOMER_LAST_NAME:
      return manipulateData<PaymentRequest>(state, { customer_last_name: action.customer_last_name })
    case ActionTypes.SET_CUSTOMER_PASSWORD:
      return manipulateData<PaymentRequest>(state, { password: action.password })
    case ActionTypes.SET_CUSTOMER_CONFIRM_PASSWORD:
      return manipulateData<PaymentRequest>(state, { password_confirmation: action.password_confirmation })
    case ActionTypes.SET_CUSTOMER_DATE_OF_BIRTH:
      return manipulateData<PaymentRequest>(state, { customer_date_of_birth: action.customer_date_of_birth })
    case ActionTypes.SET_CUSTOMER_ADDRESS:
      return manipulateData<PaymentRequest>(state, { customer_address: action.customer_address })
    case ActionTypes.SET_CUSTOMER_ZIPCODE:
      return manipulateData<PaymentRequest>(state, { customer_zipcode: action.customer_zipcode })
    case ActionTypes.SET_CUSTOMER_CITY:
      return manipulateData<PaymentRequest>(state, { customer_city: action.customer_city })
    case ActionTypes.SET_CUSTOMER_PHONE:
      return manipulateData<PaymentRequest>(state, { customer_phone: action.customer_phone })
    case ActionTypes.SET_CUSTOMER_EMAIL:
      return manipulateData<PaymentRequest>(state, { customer_email: action.customer_email.toLowerCase() })
    case ActionTypes.SET_COUPON_CODE:
      return manipulateData<PaymentRequest>(state, { coupon_code: action.coupon_code })
    case ActionTypes.SET_TERMS_AND_CONDITIONS:
      return manipulateData<PaymentRequest>(state, { terms_and_conditions: action.terms_and_condition })
    case ActionTypes.SET_HAS_COUPON_CODE:
      return manipulateData<PaymentRequest>(state, { has_coupon_code: action.has_coupon_code, coupon_code: undefined })
    case ActionTypes.SET_NEWSLETTER:
      return manipulateData<PaymentRequest>(state, { newsletter: action.newsletter })
    case ActionTypes.SET_GIFT_TYPE:
      return manipulateData<PaymentRequest>(state, { gift_type: action.gift_type })
    case ActionTypes.SET_GIFT_BUYER_NAME:
      return manipulateData<PaymentRequest>(state, { gift_buyer_name: action.gift_buyer_name })
    case ActionTypes.SET_GIFT_BUYER_EMAIL:
      return manipulateData<PaymentRequest>(state, { gift_buyer_email: action.gift_buyer_email.toLowerCase() })
    case ActionTypes.SET_GIFT_BUYER_PHONE:
      return manipulateData<PaymentRequest>(state, { gift_buyer_phone: action.gift_buyer_phone })
    case ActionTypes.SET_GIFT_RECEIVER_FIRST_NAME:
      return manipulateData<PaymentRequest>(state, { gift_receiver_first_name: action.gift_receiver_first_name })
    case ActionTypes.SET_GIFT_RECEIVER_LAST_NAME:
      return manipulateData<PaymentRequest>(state, { gift_receiver_last_name: action.gift_receiver_last_name })
    case ActionTypes.SET_GIFT_RECEIVER_CITY:
      return manipulateData<PaymentRequest>(state, { gift_receiver_city: action.gift_receiver_city })
    case ActionTypes.SET_GIFT_RECEIVER_ADDRESS:
      return manipulateData<PaymentRequest>(state, { gift_receiver_address: action.gift_receiver_address })
    case ActionTypes.SET_GIFT_RECEIVER_ZIP_CODE:
      return manipulateData<PaymentRequest>(state, { gift_receiver_zip_code: action.gift_receiver_zip_code })
    case ActionTypes.SET_GIFT_RECEIVER_ORGANISATION:
      return manipulateData<PaymentRequest>(state, { gift_receiver_organisation: action.gift_receiver_organisation })
    case ActionTypes.SET_GIFT_GREETINGS_MESSAGE:
      return manipulateData<PaymentRequest>(state, { gift_greetings_message: action.gift_greetings_message })
    case ActionTypes.SET_GIFT_SET_AT:
      return manipulateData<PaymentRequest>(state, { gift_send_at: action.gift_send_at })
    case ActionTypes.SET_GIFT_INFO:
      return manipulateData<PaymentRequest>(state, { gift_info: action.gift_info })
    case ActionTypes.INCREMENT_DIGITAL_GIFT_AMOUNT:
      return manipulateData<PaymentRequest>(state, {
        amount: state.data.gift_type === GiftType.LETTER ? 1 : state.data.amount + 1,
        gift_type: GiftType.DIGITAL,
        gift_info: createEmptyGiftInfoArray(state.data.gift_type === GiftType.LETTER ? 1 : state.data.amount + 1),
      })
    case ActionTypes.INCREMENT_LETTER_GIFT_AMOUNT:
      return manipulateData<PaymentRequest>(state, {
        amount: state.data.gift_type === GiftType.DIGITAL ? 1 : state.data.amount + 1,
        gift_type: GiftType.LETTER,
        gift_info: undefined,
      })
    case ActionTypes.DECREMENT_DIGITAL_GIFT_AMOUNT:
      return manipulateData<PaymentRequest>(state, {
        amount: state.data.gift_type === GiftType.LETTER || state.data.amount === 1 ? 1 : state.data.amount - 1,
        gift_type: GiftType.DIGITAL,
        gift_info: createEmptyGiftInfoArray(
          state.data.gift_type === GiftType.LETTER || state.data.amount === 1 ? 1 : state.data.amount - 1
        ),
      })
    case ActionTypes.DECREMENT_LETTER_GIFT_AMOUNT:
      return manipulateData<PaymentRequest>(state, {
        amount: state.data.gift_type === GiftType.DIGITAL || state.data.amount === 1 ? 1 : state.data.amount - 1,
        gift_type: GiftType.LETTER,
        gift_info: undefined,
      })
    case ActionTypes.SET_GIFT_INFO_RECEIVER_EMAIL:
      return manipulateData<PaymentRequest>(state, {
        gift_info: state.data.gift_info?.map((giftInfo, index) =>
          index === action.index ? { ...giftInfo, receiver_email: action.receiver_email.toLowerCase() } : giftInfo
        ),
      })
    case ActionTypes.SET_GIFT_INFO_RECEIVER_NAME:
      return manipulateData<PaymentRequest>(state, {
        gift_info: state.data.gift_info?.map((giftInfo, index) =>
          index === action.index ? { ...giftInfo, receiver_name: action.receiver_name } : giftInfo
        ),
      })
    case ActionTypes.SET_GIFT_INFO_RECEIVER_PHONE:
      return manipulateData<PaymentRequest>(state, {
        gift_info: state.data.gift_info?.map((giftInfo, index) =>
          index === action.index ? { ...giftInfo, receiver_phone: action.receiver_phone } : giftInfo
        ),
      })
    case ActionTypes.SET_ERRORS:
      return manipulateError<PaymentRequest>(state, action.errors)
    case ActionTypes.RESET:
      if (action.data) {
        return { ...initialState, ...action.data }
      }
      return initialState
    default:
      throw new Error()
  }
}

export default reducer
