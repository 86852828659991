import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DoubleInputContainer from '../UiKit/Input/DoubleInputContiner'
import Input from '../UiKit/Input'
import {
  createSetCustomerFirstNameAction,
  createSetCustomerLastNameAction,
  createSetCustomerDateOfBirthAction,
  createSetCustomerAddressAction,
  createSetCustomerZipcodeAction,
  createSetCustomerCityAction,
  createSetCustomerPhoneAction,
  createSetCustomerEmailAction,
  createSetCustomerPasswordAction,
  createSetCustomerConfirmPasswordAction,
} from '../../Contexts/PaymentRequestContext/reducer'
import PaymentRequestContext from '../../Contexts/PaymentRequestContext'
import PhoneInput from '../UiKit/PhoneInput'
import Text from '../UiKit/Text'

const IndividualForm: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    state: { data, errors },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onChangeFistName = (value: string): void => dispatch(createSetCustomerFirstNameAction(value))
  const onChangeLastName = (value: string): void => dispatch(createSetCustomerLastNameAction(value))
  const onChangeYearOfBirth = (value: string): void => dispatch(createSetCustomerDateOfBirthAction(value))
  const onChangeStreetAddress = (value: string): void => dispatch(createSetCustomerAddressAction(value))
  const onChangeZipCode = (value: string): void => dispatch(createSetCustomerZipcodeAction(value))
  const onChangeTown = (value: string): void => dispatch(createSetCustomerCityAction(value))
  const onChangePhone = (value: string): void => dispatch(createSetCustomerPhoneAction(value))
  const onChangeEmailAddress = (value: string): void => dispatch(createSetCustomerEmailAction(value))
  const onChangePassword = (value: string): void => dispatch(createSetCustomerPasswordAction(value))
  const onChangeConfirmPassword = (value: string): void => dispatch(createSetCustomerConfirmPasswordAction(value))

  return (
    <Container>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredFirstName')}
          onChange={onChangeFistName}
          value={data.customer_first_name || ''}
          errorMessage={errors?.customer_first_name}
          id="requiredFirstName"
        />
        <Input
          placeholder={t('requiredLastName')}
          onChange={onChangeLastName}
          value={data.customer_last_name || ''}
          errorMessage={errors?.customer_last_name}
          id="requiredLastName"
        />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredYearOfBirth')}
          onChange={onChangeYearOfBirth}
          value={data.customer_date_of_birth || ''}
          errorMessage={errors?.customer_date_of_birth}
          id="requiredYearOfBirth"
        />
        <Input
          placeholder={t('requiredStreetAddress')}
          onChange={onChangeStreetAddress}
          value={data.customer_address || ''}
          errorMessage={errors?.customer_address}
          id="requiredStreetAddress"
        />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredZipCode')}
          onChange={onChangeZipCode}
          value={data.customer_zipcode || ''}
          errorMessage={errors?.customer_zipcode}
          id="requiredZipCode"
        />
        <Input
          placeholder={t('requiredTown')}
          onChange={onChangeTown}
          value={data.customer_city || ''}
          errorMessage={errors?.customer_city}
          id="requiredTown"
        />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredEmailAddress')}
          onChange={onChangeEmailAddress}
          value={data.customer_email || ''}
          errorMessage={errors?.customer_email}
          id="requiredEmailAddress"
        />
        <PhoneInput
          placeholder={t('requiredPhone')}
          value={data.customer_phone || ''}
          onChange={onChangePhone}
          errorMessage={errors?.customer_phone}
          id="requiredPhone"
        />
      </DoubleInputContainer>
      <Text className="paragraph">{t('createPasswordForMuseumCard')}</Text>
      <DoubleInputContainer>
        <Input
          type="password"
          placeholder={t('password')}
          onChange={onChangePassword}
          value={data.password || ''}
          errorMessage={errors?.password}
          id="password"
        />
        <Input
          type="password"
          placeholder={t('confirmPassword')}
          onChange={onChangeConfirmPassword}
          value={data.password_confirmation || ''}
          errorMessage={errors?.password_confirmation}
          id="confirmPassword"
        />
      </DoubleInputContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .paragraph {
    font-size: 14px;
    margin-top: 15px;
    display: inline-block;
  }
`

export default IndividualForm
