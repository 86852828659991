import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../UiKit/Text'
import colors from '../../constants/colors'
import { useTranslation } from 'react-i18next'

interface Props {
  totalSteps: number
  currentStep: number
}

const StepsDisplay: React.FunctionComponent<Props> = ({ totalSteps, currentStep }: Props) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Text type={TextType.BOLD} className="step-text">
        {t('step').toLocaleUpperCase()}
        {currentStep}/{totalSteps}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: center;
  .step-text {
    color: ${colors.grey};
  }
`

export default StepsDisplay
