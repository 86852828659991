import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DoubleInputContainer from '../../../UiKit/Input/DoubleInputContiner'
import Input from '../../../UiKit/Input'
import Text, { TextType } from '../../../UiKit/Text'
import styled from 'styled-components'
import {
  createSetGiftBuyerNameAction,
  createSetGiftBuyerEmailAction,
  createSetGiftGreetingMessageAction,
  createSetGiftReceiverFirstNameAction,
  createSetGiftReceiverAddressAction,
  createSetGiftReceiverZipCodeAction,
  createSetGiftReceiverCityAction,
  createSetGiftReceiverLastNameAction,
  createSetGiftBuyerPhoneAction,
  createSetGiftReceiverOrganisationAction,
} from '../../../../Contexts/PaymentRequestContext/reducer'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'
import PhoneInput from '../../../UiKit/PhoneInput'
import TextArea from '../../../UiKit/Input/TextArea'

const EnvelopeSingleForm: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    state: { data, errors },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onChangeGiftBuyerName = (value: string): void => dispatch(createSetGiftBuyerNameAction(value))
  const onChangeGiftBuyerEmail = (value: string): void => dispatch(createSetGiftBuyerEmailAction(value))
  const onChangeGiftGreetingMessage = (value: string): void => dispatch(createSetGiftGreetingMessageAction(value))
  const onChangeGiftReceiverFirstName = (value: string): void => dispatch(createSetGiftReceiverFirstNameAction(value))
  const onChangeGiftReceiverAddress = (value: string): void => dispatch(createSetGiftReceiverAddressAction(value))
  const onChangeGiftReceiverZipCode = (value: string): void => dispatch(createSetGiftReceiverZipCodeAction(value))
  const onChangeGiftReceiverCity = (value: string): void => dispatch(createSetGiftReceiverCityAction(value))
  const onChangeGiftReceiverLastName = (value: string): void => dispatch(createSetGiftReceiverLastNameAction(value))
  const onChangeGiftBuyerPhone = (value: string): void => dispatch(createSetGiftBuyerPhoneAction(value))
  const onChangeGiftReceiverOrganisation = (value: string): void =>
    dispatch(createSetGiftReceiverOrganisationAction(value))

  return (
    <Styles>
      <Text type={TextType.BOLD} className="title-margin">
        {t('thePostalAddressToWhichTheGiftOrder', { amount: data.amount })}
      </Text>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredFirstName')}
          onChange={onChangeGiftReceiverFirstName}
          value={data.gift_receiver_first_name || ''}
          errorMessage={errors?.gift_receiver_first_name}
          id={'requiredFirstName'}
        />
        <Input
          placeholder={t('requiredLastName')}
          onChange={onChangeGiftReceiverLastName}
          value={data.gift_receiver_last_name || ''}
          errorMessage={errors?.gift_receiver_last_name}
          id={'requiredLastName'}
        />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredCompany')}
          onChange={onChangeGiftReceiverOrganisation}
          value={data.gift_receiver_organisation}
          errorMessage={errors?.gift_receiver_organisation}
          id={'requiredCompany'}
        />
        <Input
          placeholder={t('requiredStreetAddress')}
          onChange={onChangeGiftReceiverAddress}
          value={data.gift_receiver_address || ''}
          errorMessage={errors?.gift_receiver_address}
          id={'requiredStreetAddress'}
        />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredZipCode')}
          onChange={onChangeGiftReceiverZipCode}
          value={data.gift_receiver_zip_code || ''}
          errorMessage={errors?.gift_receiver_zip_code}
          id={'requiredZipCode'}
        />
        <Input
          placeholder={t('requiredTown')}
          onChange={onChangeGiftReceiverCity}
          value={data.gift_receiver_city || ''}
          errorMessage={errors?.gift_receiver_city}
          id={'requiredTown'}
        />
      </DoubleInputContainer>
      <Text type={TextType.BOLD} className="title-margin">
        {t('writeYourGreetingToGiftReceiver')}
      </Text>
      <Text className="title-display">{t('pleaseNoteThatTheSameMessageIsSentToAllTheReceivers')}</Text>
      <TextArea
        onChange={onChangeGiftGreetingMessage}
        inputClassName={'message-input'}
        value={data.gift_greetings_message || ''}
        errorMessage={errors?.gift_greetings_message}
      />
      <Text type={TextType.BOLD} className="title-margin">
        {t('yourOwnContactInformation')}
      </Text>
      <Text className="title-display">{t('weDeliverAReceiptOfYourPurchaseToYourEmail')}</Text>
      <Input
        placeholder={t('requiredName')}
        onChange={onChangeGiftBuyerName}
        value={data.gift_buyer_name || ''}
        errorMessage={errors?.gift_buyer_name}
        id={'requiredName'}
      />
      <DoubleInputContainer>
        <Input
          placeholder={t('requiredEmailAddress')}
          onChange={onChangeGiftBuyerEmail}
          value={data.gift_buyer_email || ''}
          errorMessage={errors?.gift_buyer_email}
          id={'requiredEmailAddress'}
        />
        <PhoneInput
          placeholder={t('requiredPhone')}
          value={data.gift_buyer_phone || ''}
          onChange={onChangeGiftBuyerPhone}
          errorMessage={errors?.gift_buyer_phone}
          id={'requiredPhone'}
        />
      </DoubleInputContainer>
    </Styles>
  )
}

const Styles = styled.div`
  justify-content: left;
  text-align: left;
  .title-display {
    display: inline-block;
    margin: 10px 0;
  }
  .message-input {
    height: 110px;
  }
`

export default EnvelopeSingleForm
