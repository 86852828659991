import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as queryString from 'query-string'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import UpdateTransactionRequest from '../../../Models/UpdateTransactionRequest'
import updateTransaction from '../../../store/webshop/payment/update/actions/updateTransaction'
import colors from '../../../constants/colors'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/core'
import RoutePaths from '../../../Routes/RoutePaths'

type Props = MappedDispatch

const spinner = css`
  margin: auto;
  display: block;
`

const PaymentFailure: React.FunctionComponent<Props> = ({ paymentFailure }) => {
  const location = useLocation()
  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    const newFailObject: UpdateTransactionRequest = {
      id: queryParams.transaction_id,
      status: location.pathname === RoutePaths.PAYMENT_CANCEL ? 'cancelled' : 'failed',
    }
    if (queryParams.method === 'solinor' || queryParams.method === 'edenred') {
      // eslint-disable-next-line @typescript-eslint/camelcase
      newFailObject.filing_code = queryParams.filing_code
    }
    paymentFailure(newFailObject)
  }, [location, paymentFailure])

  return <ClipLoader size={150} color={colors.primary} css={spinner} />
}

interface MappedDispatch {
  paymentFailure: (data: UpdateTransactionRequest) => void
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  paymentFailure: (data: UpdateTransactionRequest): any => dispatch(updateTransaction(data)),
})

export default connect(undefined, mapDispatchToProps)(PaymentFailure)
