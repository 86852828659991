import React from 'react'
import styled from 'styled-components'
import BuyHeader from '../../../BuyHeader'
import { useTranslation } from 'react-i18next'
import Text from '../../../UiKit/Text'
import ReturnButton from '../../../UiKit/Button/ReturnButton'
import StepsDisplay from '../../StepsDisplay'
import giftIcon from '../../../../assets/images/giftIcon.png'
import DigitalGiftForm from './DigitalGiftForm'

const GiftForm: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <div className="container">
        <div className="return-button">
          <ReturnButton />
        </div>
        <BuyHeader imageSource={giftIcon} title={t('theDeliveryOfTheGiftOrder')} />
        <div className="description">
          <Text className="font-size">{t('theDeliveryOfTheDigitalGiftExplanation1')}</Text>
          <Text className="font-size">{t('theDeliveryOfTheDigitalGiftExplanation2')}</Text>
        </div>
        <StepsDisplay totalSteps={2} currentStep={1} />
        <DigitalGiftForm />
        <div className="return-button">
          <ReturnButton />
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
  .description {
    display: grid;
    margin: 15px 0;
  }
  .font-size {
    font-size: 12pt;
  }
`

export default GiftForm
