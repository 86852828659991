import Types from './types'

export type ActionTypes =
  | { type: Types.GET_RECEIPT_REQUEST }
  | { type: Types.GET_RECEIPT_SUCCESS; data: any }
  | { type: Types.GET_RECEIPT_FAILURE; error: any }

export const createGetReceiptRequestAction = (): ActionTypes => ({ type: Types.GET_RECEIPT_REQUEST })
export const createGetReceiptSuccessAction = (data: any): ActionTypes => ({
  type: Types.GET_RECEIPT_SUCCESS,
  data,
})
export const createGetReceiptFailureAction = (error: any): ActionTypes => ({
  type: Types.GET_RECEIPT_FAILURE,
  error,
})
