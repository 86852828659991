enum PaymentMethods {
  PAYTRAIL = 'paytrail',
  SOLINOR = 'solinor',
  SMARTUM = 'smartum',
  EPASSI = 'epassi',
  EDENRED = 'edenred',
  EAZYBREAK = 'eazybreak',
}

export default PaymentMethods
