import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createGetPricesRequestAction, createGetPricesSuccessAction, createGetPricesFailureAction } from './creators'
import ReceiptAPI from '../../../../api/ReceiptAPI'

export const getPrices = (): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: any
): Promise<void> => {
  if (!getState().webshop.price.data) {
    dispatch(createGetPricesRequestAction())
    try {
      const priceAPI = new ReceiptAPI()
      const response = await priceAPI.getPrice()
      dispatch(createGetPricesSuccessAction(response.data))
    } catch (error) {
      dispatch(createGetPricesFailureAction(error))
    }
  }
}
