import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from './UiKit/Text'

interface Props {
  children: React.ReactElement
  description: string
  header?: string
  description2?: string
}
const ServiceDescription: React.FunctionComponent<Props> = ({ header, children, description, description2 }: Props) => {
  console.log(description)
  return (
    <Styles>
      {children}
      <Text className="paragraph">
        <Text type={TextType.BOLD} className="header">
          {header}
        </Text>
        <Text>
          {description}
          <a target="_blank" rel="noopener noreferrer" href="https://yritystilaus.museokortti.fi/">
            <Text>{description2}</Text>
          </a>
        </Text>
      </Text>
    </Styles>
  )
}

const Styles = styled.div`
  display: flex;
  margin: 30px 0;
  .header {
    display: block;
  }
  .paragraph {
    justify-content: flex-start;
    text-align: left;
    margin-left: 1rem;
  }
`

export default ServiceDescription
