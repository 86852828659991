import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken } from '../utils/storage'
import { BASE_NEW_URL } from '../constants/env'

interface DefaultHeaders {
  'Content-Type': string
  Accept: string
  Authorization?: string
}

export default class API {
  private apiProvider: AxiosInstance
  private baseUrl: string
  constructor() {
    this.baseUrl = BASE_NEW_URL
    this.apiProvider = axios.create({
      baseURL: this.baseUrl,
      timeout: 300000,
      withCredentials: false,
    })
  }
  private static errorHandler<T>(error: T): T {
    // Error handling.
    return error
  }
  private static async getDefaultHeaders(): Promise<DefaultHeaders> {
    const headers: DefaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }

    const token = await getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return headers
  }
  private static async getHeaders(headers: any = {}): Promise<any> {
    const defaultHeaders = await API.getDefaultHeaders()
    return { ...defaultHeaders, ...headers }
  }
  public async post<RequestBody, ResponseBody, Headers = undefined>(
    url: string,
    body: RequestBody,
    headers?: Headers,
    otherOptions?: AxiosRequestConfig,
    baseUrl: string = this.baseUrl
  ): Promise<AxiosResponse<ResponseBody>> {
    const requestHeaders = await API.getHeaders(headers)
    try {
      return await this.apiProvider.post(`${baseUrl}/${url}`, body, {
        headers: requestHeaders,
        ...otherOptions,
      })
    } catch (error) {
      throw API.errorHandler(error)
    }
  }
  public async get<ResponseBody, Params = undefined, Headers = undefined>(
    url: string,
    params?: Params,
    headers?: Headers,
    otherOptions?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBody>> {
    const requestHeaders = await API.getHeaders(headers)
    try {
      return await this.apiProvider.get(url, {
        params,
        headers: requestHeaders,
        ...otherOptions,
      })
    } catch (error) {
      throw API.errorHandler(error)
    }
  }
  public async delete<ResponseBody, Headers = undefined>(
    url: string,
    headers?: Headers
  ): Promise<AxiosResponse<ResponseBody>> {
    const requestHeaders = await API.getHeaders(headers)
    try {
      return await this.apiProvider.delete(url, {
        headers: requestHeaders,
      })
    } catch (error) {
      throw API.errorHandler(error)
    }
  }
  public async put<RequestBody, ResponseBody, Headers = undefined>(
    url: string,
    body: RequestBody,
    headers?: Headers
  ): Promise<AxiosResponse<ResponseBody>> {
    const requestHeaders = await API.getHeaders(headers)
    try {
      return await this.apiProvider.put(url, body, {
        headers: requestHeaders,
      })
    } catch (error) {
      throw API.errorHandler(error)
    }
  }
  public async patch<RequestBody, ResponseBody, Headers = undefined>(
    url: string,
    body: RequestBody,
    headers?: Headers
  ): Promise<AxiosResponse<ResponseBody>> {
    const requestHeaders = await API.getHeaders(headers)
    try {
      return await this.apiProvider.patch<ResponseBody>(url, body, {
        headers: requestHeaders,
      })
    } catch (error) {
      throw API.errorHandler(error)
    }
  }
}
