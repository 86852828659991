import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { createPostFormFailureAction, createPostFormRequestAction, createPostFormSuccessAction } from './creators'
import PaymentRequest from '../../../../../Models/PaymentRequest'
import ReceiptAPI from '../../../../../api/ReceiptAPI'
import browserHistory from '../../../../../browserHistory'
import RoutePaths from '../../../../../Routes/RoutePaths'
import { setPaymentFormResponse, setPaymentType, setActiveCoupon } from '../../../../../utils/storage'
import { beginCheckout } from '../../../../../utils/tagManagerReport'

export const postForm = (data: PaymentRequest, language: string): ThunkAction<any, any, any, any> => async (
  dispatch: Dispatch,
  getState: any
): Promise<void> => {
  dispatch(createPostFormRequestAction(data))

  try {
    const peceiptAPI = new ReceiptAPI()
    const response = await peceiptAPI.postForm(data, language)
    const price = getState().webshop.price.data.card
    dispatch(createPostFormSuccessAction(response.data))
    setPaymentFormResponse(response.data)
    setPaymentType(getState().webshop.payment.form.payment_type)
    setActiveCoupon(!!data?.coupon_code ? 'yes' : 'no')
    beginCheckout(price)
    browserHistory.push(RoutePaths.PAYMENT_METHOD)
  } catch (error) {
    if (error.response.status === 422) {
      dispatch(createPostFormFailureAction(error.response.data.errors))
    } else {
      dispatch(createPostFormFailureAction(error.response.data))
    }
  }
}
