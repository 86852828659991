import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../../constants/colors'

interface Props {
  children?: React.ReactNode
  errorMessage?: string
  errorClassName?: string
  className?: string
  doesntHaveBorder?: boolean
}
//TODO, need to add error text messages as well
const InputContainer: React.FunctionComponent<Props> = ({
  children,
  errorMessage = '',
  className,
  doesntHaveBorder,
}) => {
  return (
    <Styles className={className} hasError={!!errorMessage} doesntHaveBorder={doesntHaveBorder}>
      {children}
    </Styles>
  )
}

interface StyledProps {
  hasError: boolean
  doesntHaveBorder?: boolean
}

const Styles = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  .error-message {
    margin-top: 2px;
    text-align: left;
  }
  textarea,
  input,
  select {
    color: ${colors.black};
    font-size: 16px;
    font-family: Montserrat-Regular;
    line-height: 22px;
    background-color: ${colors.white};
    border: ${({ doesntHaveBorder }: StyledProps): string =>
      doesntHaveBorder ? '0' : `1.5px solid ${colors.primary}`};
    padding: 15px 10px;
    text-decoration: none;
    cursor: text;
    width: 100%;
    box-sizing: border-box;
    ::placeholder {
      color: ${colors.black};
      opacity: 0.5;
    }
    :focus {
      ::placeholder {
        color: ${colors.black};
        opacity: 0.7;
      }
      outline: none;
      border-radius: 2px;
    }
    ${({ hasError }: StyledProps): any =>
      hasError &&
      css`
        border: 1px solid ${colors.error};
        background-color: #ffbbbb;
      `};
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 7px 0;
    }
  }
`

export default InputContainer
