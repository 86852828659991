import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import iconOmaKortti72 from '../../assets/images/homePage/icon-oma-kortti-72.svg'
import iconOmaKorttiLahja72 from '../../assets/images/homePage/icon-oma-kortti-lahja-72.svg'
import iconQuestion24 from '../../assets/images/homePage/icon-question-24.svg'
import illustrationSalesman from '../../assets/images/homePage/illustration-salesman.png'
import logoEdenred from '../../assets/images/homePage/logo-edenred.png'
import logoSmartum from '../../assets/images/homePage/logo-smartum.png'
import logoEpassi from '../../assets/images/homePage/logo-epassi.png'
import { useHistory } from 'react-router-dom'
import RoutePaths from '../../Routes/RoutePaths'
import { EXTEND_URL } from '../../constants/env'
import iconArrowDownLarge24 from '../../assets/images/homePage/icon-arrow-down-large-24.svg'
import iconCheck32 from '../../assets/images/homePage/icon-check-32.svg'
import PaymentRequestContext from '../../Contexts/PaymentRequestContext'
import ShoppingType from '../../Models/ShoppingType'
import { createSetShoppingTypeAction, createSetAmountAction } from '../../Contexts/PaymentRequestContext/reducer'
import { createSetPaymentType } from '../../store/webshop/payment/form/actions/creators'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { formatPrice } from '../../utils'
import { addToCardTag } from '../../utils/tagManagerReport'

interface LocalProps {
  cardPrice?: number
  giftPrice?: number
  renewalBeforeExpiry?: number
  renewalAfterExpiry?: number
}
interface Props extends LocalProps, MappedDispatch {}

//TODO add all translations for this page
const ChooseShoppingTypeSection: React.FunctionComponent<Props> = ({
  giftPrice,
  cardPrice,
  renewalBeforeExpiry,
  renewalAfterExpiry,
  createSetPaymentType,
}) => {
  const [personalType, setPersonalType] = useState<'personal' | 'extend'>('personal')
  const [giftType, setGiftType] = useState('gift')
  const { push } = useHistory()
  const { dispatch } = useContext(PaymentRequestContext)
  const { t } = useTranslation()

  const changeShoppingType = (value: ShoppingType): void => dispatch(createSetShoppingTypeAction(value))
  const changeAmount = (value: number): void => dispatch(createSetAmountAction(value))

  const onClickPersonal = (): void => {
    if (personalType === 'personal') {
      addToCardTag('buy', cardPrice)
      changeShoppingType(ShoppingType.PERSONAL)
      createSetPaymentType(ShoppingType.PERSONAL)
      changeAmount(1)
      push(RoutePaths.PERSONAL)
    } else {
      window.location.href = EXTEND_URL
    }
  }
  const onClickGift = (): void => {
    addToCardTag('gift', giftPrice)
    changeShoppingType(ShoppingType.GIFT)
    createSetPaymentType(ShoppingType.GIFT)
    push(RoutePaths.GIFT)
  }

  const [isPersonalOpen, setIsPersonalOpen] = useState<boolean>(false)
  const [isGiftOpen, setIsGiftOpen] = useState<boolean>(false)

  //TODO need to refactor this page !!
  return (
    <Section>
      <div className="mod-shopOptions__contentWrapper">
        <ul className="mod-shopOptions__options">
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
          <li className={`mod-shopOptions__singleOption ${isPersonalOpen && '-open'}`}>
            <div className="mod-shopOptions__cardWrapper">
              <div className="mod-shopOptions__cardTeaser">
                <img className="mod-shopOptions__icon" src={iconOmaKortti72} alt="Osta Museokortti itsellesi" />
                <div className="mod-shopOptions__cardTextContent">
                  <h2 className="mod-shopOptions__title">{t('buyMuseumCard')}</h2>
                  <div>
                    <div className="mod-shopOptions__text">{t('buyNewMuseumCardOrExtend')}</div>
                    <br />
                    <div className="mod-shopOptions__text">
                      <div className="bold-text" dangerouslySetInnerHTML={{ __html: t('campaignBoldText') }} />
                      <div dangerouslySetInnerHTML={{ __html: t('campaignText') }} />
                    </div>
                    <span className="mod-shopOptions__price -hideOnDesktop">{cardPrice && formatPrice(cardPrice)}</span>
                  </div>
                </div>
                <button
                  onClick={setIsPersonalOpen.bind(undefined, !isPersonalOpen)}
                  className="mod-shopOptions__showMore transparent-box"
                  id={'setIsPersonalOpen'}
                />
              </div>
              <div className="mod-shopOptions__form">
                <fieldset className="mod-shopOptions__radioGroup">
                  <div className="mod-shopOptions__radioWrapper input-radio">
                    <input
                      onChange={setPersonalType.bind(undefined, 'personal')}
                      className="input-radio__input"
                      type="radio"
                      id="buy-card"
                      checked={personalType === 'personal'}
                    />
                    <label className="input-radio__label" htmlFor="buy-card">
                      {t('museumCardForYourSelf')}
                      {cardPrice ? formatPrice(cardPrice) : 0}
                    </label>
                  </div>
                  <div className="mod-shopOptions__radioWrapper input-radio">
                    <input
                      onChange={setPersonalType.bind(undefined, 'extend')}
                      className="input-radio__input"
                      type="radio"
                      id="continue-card"
                      checked={personalType === 'extend'}
                    />
                    <label className="input-radio__label" htmlFor="continue-card">
                      {t('extendThePeriodOfValidity')}
                      {renewalBeforeExpiry !== renewalAfterExpiry
                        ? renewalBeforeExpiry
                          ? `${formatPrice(renewalBeforeExpiry)} / `
                          : 0
                        : null}
                      {renewalAfterExpiry ? formatPrice(renewalAfterExpiry) : 0}
                    </label>
                  </div>
                </fieldset>
                <button
                  className="mod-shopOptions__submitButton"
                  type="button"
                  onClick={onClickPersonal}
                  id={'onClickPersonal'}
                >
                  <span className="choose-shopping-type_button">{t('continueButton')}</span>
                </button>
              </div>
            </div>
            <ul className="mod-shopOptions__featureList">
              <li className="mod-shopOptions__feature">{t('whenYouBuyCardOnline')}</li>
              <li className="mod-shopOptions__feature">{t('youCanAlsoUseCard')}</li>
              <li className="mod-shopOptions__feature">{t('youWillReceiveYourCustomerID')}</li>
              <li className="mod-shopOptions__feature">{t('thePlasticCardWillBeDelivered')}</li>
              <li className="mod-shopOptions__feature">{t('thePeriodOfValidity')}</li>
              <li className="mod-shopOptions__feature">
                {t('whenYouExtendThePeriodOfValidity', {
                  price: renewalBeforeExpiry && formatPrice(renewalBeforeExpiry),
                })}
              </li>
            </ul>
          </li>
          <li className={`mod-shopOptions__singleOption ${isGiftOpen && '-open'}`}>
            <div className="mod-shopOptions__cardWrapper">
              <div className="mod-shopOptions__cardTeaser">
                <img className="mod-shopOptions__icon" src={iconOmaKorttiLahja72} alt="Osta Museokortti lahjaksi" />
                <div className="mod-shopOptions__cardTextContent">
                  <h2 className="mod-shopOptions__title">{t('museumCardAsAGift')}</h2>
                  <div>
                    <p className="mod-shopOptions__text">{t('surpriseYourFriendOrFamily')}</p>
                    <br />
                    <div className="mod-shopOptions__text" dangerouslySetInnerHTML={{ __html: t('giftCombineText') }} />
                    <span className="mod-shopOptions__price -hideOnDesktop">{giftPrice && formatPrice(giftPrice)}</span>
                  </div>
                </div>
                <button
                  onClick={setIsGiftOpen.bind(undefined, !isGiftOpen)}
                  className="mod-shopOptions__showMore transparent-box"
                  id={'setIsGiftOpen'}
                />
              </div>
              <div className="mod-shopOptions__form">
                <fieldset className="mod-shopOptions__radioGroup">
                  <div className="mod-shopOptions__radioWrapper input-radio">
                    <input
                      onChange={setGiftType.bind(undefined, 'gift')}
                      className="input-radio__input"
                      type="radio"
                      id="buy-card-present"
                      checked={giftType === 'gift'}
                    />
                    <label className="input-radio__label" htmlFor="buy-card-present">
                      {t('museumCardAsAGift')}, {giftPrice && formatPrice(giftPrice)}
                    </label>
                  </div>
                  {/* <div className="mod-shopOptions__radioWrapper input-radio">
                    <input disabled className="input-radio__input" type="radio" id="continue-card-present" />
                    <label className="input-radio__label" htmlFor="continue-card-present">
                      {t('comingUpBuyAnGift')}
                    </label>
                  </div> */}
                </fieldset>
                <button
                  className="mod-shopOptions__submitButton"
                  type="button"
                  onClick={onClickGift}
                  id={'continueAsGift'}
                >
                  <span className="choose-shopping-type_button">{t('continueAsGift')}</span>
                </button>
              </div>
            </div>
            <ul className="mod-shopOptions__featureList">
              <li className="mod-shopOptions__feature">{t('theGiftWillBeDelivered')}</li>
              <li className="mod-shopOptions__feature">{t('youCanChooseTheDeliveryDate')}</li>
              <li className="mod-shopOptions__feature">
                {t('theGiftEnvelope1')}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://museot.fi/doc/markkinointi/Museokortti_lahjakirje-2019-esimerkki-nettiin.pdf"
                >
                  <span> {t('theGiftEnvelope2')}</span>
                </a>
                {t('willBeDeliveredByPost')}
              </li>
              <li className="mod-shopOptions__feature">{t('the12MonthPeriodOfValidity')}</li>
              <li className="mod-shopOptions__feature">{t('ifTheRecipientAlreadyHasMuseumCard')}</li>
            </ul>
          </li>
          {/* eslint-enable jsx-a11y/click-events-have-key-events */}
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
        </ul>
        <div className="mod-shopOptions__moreButton">
          <a
            href="https://museot.fi/museokortti"
            target="_blank"
            rel="noopener noreferrer"
            className="banner-text-color"
          >
            {t('readMoreAboutMuseumCard')}&nbsp;›
          </a>
        </div>
        <div className="mod-shopOptions__banner">
          <a
            href="https://yritystilaus.museokortti.fi"
            target="_blank"
            rel="noopener noreferrer"
            className="mod-shopOptions__bannerWrapper"
          >
            <img className="mod-shopOptions__bannerIcon" src={iconQuestion24} alt="Question icon" />
            <img className="mod-shopOptions__bannerImage" src={illustrationSalesman} alt="Osta Museokortti laskulla" />
            <p className="mod-shopOptions__bannerText banner-text-color">{t('youCanOrderMoreThan')}</p>
          </a>
        </div>
        <div className="mod-shopOptions__benefits">
          <h2 className="mod-shopOptions__benefitsTitle">{t('useYourCulturalBenefits')}</h2>
          <ul className="mod-shopOptions__benefitsList">
            <li className="mod-shopOptions__singleBenefit">
              <img className="mod-shopOptions__benefitImage" src={logoEdenred} alt="Edenred" />
            </li>
            {/* <li className="mod-shopOptions__singleBenefit">
              <img className="mod-shopOptions__benefitImage" src={logoEazybreak} alt="Eazybreak" />
            </li> */}
            <li className="mod-shopOptions__singleBenefit">
              <img className="mod-shopOptions__benefitImage" src={logoSmartum} alt="Smartum" />
            </li>
            <li className="mod-shopOptions__singleBenefit">
              <img className="mod-shopOptions__benefitImage" src={logoEpassi} alt="ePassi" />
            </li>
          </ul>
        </div>
      </div>
    </Section>
  )
}

interface MappedDispatch {
  createSetPaymentType: (value: string) => void
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  createSetPaymentType: (value): any => dispatch(createSetPaymentType(value)),
})

export default connect(null, mapDispatchToProps)(ChooseShoppingTypeSection)

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  background-color: #fff6f5;
  padding-bottom: 6.5rem;
  position: relative;
  @media (min-width: 1024px) {
    padding-bottom: 12.5rem;
  }
  @media (min-width: 1200px) {
    padding-bottom: 15rem;
  }
  .button,
  .mod-shopOptions__submitButton {
    background-color: #ff598f;
    outline: 0;
    border: 0 none;
    display: flex;
    -webkit-appearance: none;
    appearance: none;
    height: auto;
    font-size: 0.875rem;
    line-height: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    border-radius: 3rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    transition: box-shadow 0.25s ease, background-color 0.25s ease;
    width: 100%;
  }
  .button span,
  .mod-shopOptions__submitButton span {
    margin-bottom: 0.25rem;
    color: white;
  }
  .button:hover,
  .button:focus,
  .mod-shopOptions__submitButton:hover,
  .mod-shopOptions__submitButton:focus {
    box-shadow: 0 24px 24px -16px rgba(44, 37, 61, 0.2);
    background-color: #ff407e;
  }
  .transparent-box {
    border-color: transparent;
    background-color: transparent;
  }
  .input-radio {
    position: relative;
    text-align: left;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 768px) {
    .input-radio {
      margin-bottom: 2rem;
    }
  }
  .input-radio__input {
    opacity: 0;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }
  .banner-text-color {
    color: #ff598f;
  }

  @media (min-width: 1024px) {
    .input-radio__input {
      width: 2rem;
      height: 2rem;
    }
  }
  .input-radio__label {
    padding-left: 2.25rem;
    padding-top: 0;
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    cursor: pointer;
    transition: opacity 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
  }
  @media (min-width: 768px) {
    .input-radio__label {
      padding-left: 2.5rem;
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
  @media (min-width: 1024px) {
    .input-radio__label {
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding-left: 3rem;
    }
  }
  .input-radio__label:hover:after {
    background-color: #ffdbd8;
    opacity: 0.7;
  }
  .input-radio__label:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #ffdbd8;
    transition: border-color 0.2s ease;
  }
  @media (min-width: 1024px) {
    .input-radio__label:before {
      width: 2rem;
      height: 2rem;
    }
  }
  .input-radio__label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0.375rem;
    left: 0.4375rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #ffdbd8;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  @media (min-width: 1024px) {
    .input-radio__label:after {
      top: 0.5625rem;
      left: 0.625rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  .input-radio__input:checked + .input-radio__label {
    color: #ff598f;
    font-weight: 600;
  }
  .input-radio__input:checked + .input-radio__label:before {
    background-color: #ff598f;
  }
  .input-radio__input:checked + .input-radio__label:after {
    background-color: white;
    box-shadow: 0 4px 4px 0 rgba(41, 48, 81, 0.2);
  }
  .input-radio__input:checked + .input-radio__label:after {
    opacity: 1;
  }
  .input-radio__input:disabled {
    opacity: 0.25;
  }
  .input-radio__input:disabled + .input-radio__label {
    opacity: 0.25;
  }

  html {
    background-color: #2e2e2e;
    box-sizing: border-box;
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }

  main {
    width: 100%;
    overflow: hidden;
  }

  /*------------------------------------*\\
    MAIN
\\*------------------------------------*/
  /* Border around everything */
  @media (min-width: 900px) {
    #border {
      position: absolute;
      left: 16px;
      top: 16px;
      right: 16px;
      bottom: 16px;
      z-index: 10;
      border: 2px solid white;
    }
  }

  /* global box-sizing */
  *,
  *:after,
  *:before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  /* clear */
  .clear:before,
  .clear:after {
    content: ' ';
    display: table;
  }

  .clear:after {
    clear: both;
  }

  .clear {
    *zoom: 1;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  a {
    text-decoration: none;
    color: #ff598f;
  }

  a:hover {
    color: #ff598f;
  }

  a:focus {
    outline: 0;
  }

  a:hover,
  a:active {
    outline: 0;
  }

  input:focus {
    outline: 0;
    border: 1px solid #04a4cc;
  }

  li {
    list-style: none;
  }

  /*------------------------------------*\\
    MISC
\\*------------------------------------*/
  ::selection {
    background: #04a4cc;
    color: #fff;
    text-shadow: none;
  }

  ::-webkit-selection {
    background: #04a4cc;
    color: #fff;
    text-shadow: none;
  }

  ::-moz-selection {
    background: #04a4cc;
    color: #fff;
    text-shadow: none;
  }

  /*body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: #ffd5d2;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-link;
  outline: 1px solid $color-link;
  border-radius: 30px;
}*/
  /*------------------------------------*\\
    PRINT
\\*------------------------------------*/
  @media print {
    * {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: ' (' attr(href) ')';
    }

    abbr[title]:after {
      content: ' (' attr(title) ')';
    }

    .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    @page {
      margin: 0.5cm;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  .mod-shopOptions__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__contentWrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: 1.5rem;
    }
  }
  .mod-shopOptions__options {
    display: flex;
    flex-direction: column;
    /*@include breakpoint-min-768 {
      flex-direction: row;
  }*/
  }
  @media (min-width: 768px) {
    .mod-shopOptions__options {
      grid-column: 2 / span 10;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
    }
  }
  @media (min-width: 1200px) {
    .mod-shopOptions__options {
      grid-gap: 2.5rem;
    }
  }
  .mod-shopOptions__singleOption {
    flex: 1;
  }
  .mod-shopOptions__singleOption:first-child .mod-shopOptions__cardWrapper {
    margin-top: -2rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__singleOption:first-child .mod-shopOptions__cardWrapper {
      margin-top: -3rem;
    }
  }
  @media (min-width: 768px) {
    .mod-shopOptions__singleOption:nth-child(2) .mod-shopOptions__cardWrapper {
      margin-top: -3rem;
    }
  }
  .mod-shopOptions__singleOption.-open .mod-shopOptions__form {
    display: block;
  }
  .mod-shopOptions__singleOption.-open .mod-shopOptions__featureList {
    display: block;
  }
  .mod-shopOptions__singleOption.-open .mod-shopOptions__showMore:before {
    transform: rotate(180deg);
  }
  .mod-shopOptions__cardWrapper {
    background-color: white;
    padding: 1rem;
    border-radius: 0.375rem;
    margin-bottom: 0.75rem;
    box-shadow: 0 24px 32px 0 rgba(164, 59, 51, 0.1);
    position: relative;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__cardWrapper {
      padding: 1.5rem;
    }
  }
  @media (min-width: 900px) {
    .mod-shopOptions__cardWrapper {
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (min-width: 1200px) {
    .mod-shopOptions__cardWrapper {
      padding: 4.5rem;
    }
  }
  .mod-shopOptions__cardTeaser {
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__cardTeaser {
      flex-direction: column;
      text-align: center;
    }
  }
  .mod-shopOptions__cardTextContent {
    padding-right: 20%;
    flex: 1 1 100%;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__cardTextContent {
      padding-right: 0;
    }
  }
  .mod-shopOptions__icon {
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
    flex: 1;
  }
  @media (min-width: 430px) {
    .mod-shopOptions__icon {
      flex: 1 0 5rem;
    }
  }
  @media (min-width: 768px) {
    .mod-shopOptions__icon {
      height: 4.5rem;
      width: 4.5rem;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__icon {
      margin-bottom: 1rem;
    }
  }
  .mod-shopOptions__text {
    // text-align: center;
  }
  .mod-shopOptions__title,
  .mod-shopOptions__text,
  .mod-shopOptions__price {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #2c253d;
  }
  .mod-shopOptions__title {
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    margin-bottom: 0.25rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.25rem;
      max-width: 13.75rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__title {
      font-size: 1.75rem;
      line-height: 2.25rem;
      max-width: 17.5rem;
    }
  }
  .mod-shopOptions__text {
    color: #5e334f;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__text {
      font-size: 1rem;
      line-height: 1.375rem;
      max-width: 13.75rem;
      margin: 0 auto;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__text {
      font-size: 1.25rem;
      line-height: 1.75rem;
      max-width: 18.75rem;
    }
  }
  .mod-shopOptions__price {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__price.-hideOnDesktop {
      display: none;
    }
  }
  .mod-shopOptions__showMore:before {
    content: '';
    background-image: url(${iconArrowDownLarge24});
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.875rem;
    transform: rotate(0deg);
    transition: transform 0.25s ease-in-out;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__showMore {
      display: none;
    }
  }
  .mod-shopOptions__form {
    display: none;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .mod-shopOptions__submitButton {
    margin-bottom: 1.5rem;
  }
  .mod-shopOptions__radioGroup {
    margin: 2.5rem 0;
    border: 0 none;
    padding: 0 1rem;
  }
  .mod-shopOptions__radioWrapper {
    margin-bottom: 1rem;
  }
  .mod-shopOptions__radioWrapper:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__radioWrapper {
      margin-bottom: 1.5rem;
    }
  }
  .mod-shopOptions__featureList {
    display: none;
    margin-top: 3rem;
    margin-bottom: 3rem;
    position: relative;
    padding: 0 1.5rem;
    /*&:before {
      position: absolute;
      left: rem(38px); // 14px + 24px
      top: 0;
      content: "";
      height: 100%;
      width: rem(2px);
      background-color: $color-pink;
      z-index: 0;

      @include breakpoint-min-1140 {
          left: rem(62px); // 14px + 48px
      }
  }*/
  }
  .mod-shopOptions__featureList.-open {
    display: block;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__featureList {
      display: block;
      margin-top: 4.5rem;
    }
  }
  @media (min-width: 1140px) {
    .mod-shopOptions__featureList {
      padding: 0 3rem;
    }
  }
  .mod-shopOptions__feature {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
    position: relative;
    padding-left: 2.25rem;
    color: #5e334f;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__feature {
      padding-left: 3rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__feature {
      padding-left: 3.75rem;
    }
  }
  @media (min-width: 1140px) {
    .mod-shopOptions__feature {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .mod-shopOptions__feature:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    background-image: url(${iconCheck32});
    background-size: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff6f5;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__feature:before {
      height: 2rem;
      width: 2rem;
      background-size: 2rem;
    }
  }
  .mod-shopOptions__moreButton {
    grid-column: span 12;
    text-align: center;
    margin-top: 2rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__moreButton {
      margin-top: 0;
    }
  }
  .mod-shopOptions__moreButton a {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .mod-shopOptions__banner {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
    max-width: 41.25rem;
    margin-left: auto;
    margin-right: auto;
    /*@include breakpoint-min-1140 {
      grid-column: 3 / span 8;
  }

  @include breakpoint-min-1200 {
      grid-column: 4 / span 6;
  }*/
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__banner {
      grid-column: 2 / span 10;
      width: 100%;
    }
  }
  .mod-shopOptions__bannerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    transition: box-shadow 0.25s ease-in-out;
    padding: 1.5rem 3rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__bannerWrapper {
      flex-direction: row;
      text-align: left;
      justify-content: left;
      background-color: white;
      border-radius: 3.75rem;
      box-shadow: 0 12px 20px 0 rgba(164, 59, 51, 0.05);
    }
    .mod-shopOptions__bannerWrapper:hover {
      box-shadow: 0 24px 32px -8px rgba(164, 59, 51, 0.1);
    }
  }
  .mod-shopOptions__bannerIcon {
    height: 1.5rem;
    width: 1.5rem;
    margin-bottom: 0.5rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__bannerIcon {
      margin-bottom: 0;
      margin-right: 0.75rem;
    }
  }
  .mod-shopOptions__bannerImage {
    height: 8.125rem;
    width: auto;
    position: absolute;
    right: 3rem;
    bottom: 0;
    display: none;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__bannerImage {
      display: block;
    }
  }
  .mod-shopOptions__bannerText {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__bannerText {
      text-align: left;
    }
  }
  .mod-shopOptions__benefits {
    text-align: center;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__benefits {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 1.5rem;
      align-items: center;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__benefits {
      grid-column: 2 / span 10;
    }
  }
  @media (min-width: 1140px) {
    .mod-shopOptions__benefits {
      grid-column: 3 / span 8;
    }
  }
  .mod-shopOptions__benefitsTitle {
    color: #2c253d;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__benefitsTitle {
      text-align: left;
      margin-bottom: 0;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  .mod-shopOptions__benefitsList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 15rem;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__benefitsList {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 100%;
      margin: 0;
    }
  }
  .mod-shopOptions__singleBenefit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mod-shopOptions__benefitImage {
    max-height: 5rem;
    width: auto;
  }
  @media (min-width: 768px) {
    .mod-shopOptions__benefitImage {
      max-height: 5.5rem;
    }
  }
  @media (min-width: 1024px) {
    .mod-shopOptions__benefitImage {
      max-height: 6.25rem;
    }
  }
  .choose-shopping-type_button {
    z-index: 2;
    cursor: pointer;
  }
  .bold-text {
    font-weight: 800;
  }
`
