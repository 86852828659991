import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from './UiKit/Text'
import { useTranslation } from 'react-i18next'

interface Props {
  imageSource: string
  title: string
}
const BuyHeader: React.FunctionComponent<Props> = ({ title, imageSource }: Props) => {
  const { t } = useTranslation()

  return (
    <Container>
      <img src={imageSource} alt={t('basket')} className="basket-img" />
      <Text type={TextType.TITLE} className="App-header">
        {title}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  .basket-img {
    margin-top: 2rem;
    height: 58px;
    width: 70px;
  }
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export default BuyHeader
