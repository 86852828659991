import React, { useState } from 'react'
import styled from 'styled-components'
import BuyHeader from '../BuyHeader'
import { useTranslation } from 'react-i18next'
// import Button from '../UiKit/Button'
import ReturnButton from '../UiKit/Button/ReturnButton'
import addToBasket from '../../assets/images/addToBasket.png'
import Text from '../UiKit/Text'
import StepsDisplay from '../Buy/StepsDisplay'
import MethodExplanation from './MethodExplanation'
import Solinor from './Solinor'
import Paytrail from './Paytrail'
import CulturalVoucher from './CulturalVoucher'
import PaymentMethods from '../../Models/PaymentMethods'
import PaymentForms from '../../Models/PaymentForms'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import RoutePaths from '../../Routes/RoutePaths'
import { patchTransaction } from '../../store/webshop/payment/form/actions/patchTransaction'
import { RootState } from '../../store'
import { ThunkDispatch } from 'redux-thunk'
import { ClipLoader } from 'react-spinners'
import colors from '../../constants/colors'
import { addPaymentInfo } from '../../utils/tagManagerReport'

interface Props extends MappedState, MappedDispatch {}

const PaymentMethod: React.FunctionComponent<Props> = ({ paymentForms, type, patchTransaction, cardPrice }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  if (!paymentForms) {
    history.replace(RoutePaths.HOME)
    return null
  }

  const onClickContinue = (methond: PaymentMethods, id: string | undefined): void => {
    if (!loading) {
      setLoading(true)
      addPaymentInfo(methond, cardPrice)
      patchTransaction(methond, id)
    }
  }
  return (
    <Container>
      <div className="container">
        <div className="return-button">
          <ReturnButton />
        </div>
        <BuyHeader imageSource={addToBasket} title={t('chooseYourPayingMethod')} />
        {type === 'personal' ? (
          <>
            <Text className="description">{t('chooseThePayingMethodYouPrefer')}</Text>
            <Text>{t('theReceiptWillBeSentToYouByEmailExplanation')}</Text>
          </>
        ) : (
          <Text className="description">{t('selectTheDesiredPaymentMethod')}</Text>
        )}
        <StepsDisplay totalSteps={2} currentStep={2} />
        <MethodExplanation title={t('onlineBank')} />
        <Paytrail
          type={type}
          onChange={(bankId): void => {
            onClickContinue(PaymentMethods.PAYTRAIL, bankId)
          }}
        />
        {type === 'personal' && (
          <>
            <MethodExplanation title={t('culturalVoucher')} explanation={t('takeAdvantageOfTheCulturalBenefit')} />
            <CulturalVoucher onChange={onClickContinue} />
          </>
        )}
        <MethodExplanation
          title={t('paymentCard')}
          explanation={type === 'personal' ? t('possibilityToActivateStandingOrder') : ''}
        />
        <Solinor
          onChange={(bankId): void => {
            onClickContinue(PaymentMethods.SOLINOR, bankId)
          }}
        />
        {/* <Button onClick={onClickContinue} id="continue">
          {t('continue')}
        </Button> */}
        <div className="return-button">
          <ReturnButton />
        </div>
      </div>
      {loading && (
        <div className="spinContainer">
          <ClipLoader size={150} color={colors.primary} />
        </div>
      )}
    </Container>
  )
}

interface MappedState {
  paymentForms?: PaymentForms[]
  type: string
  payemntDone: boolean
  cardPrice: any
}

const mapStateToProps = (state: RootState): MappedState => {
  return {
    paymentForms: state.webshop.payment.form.data?.payment_forms,
    type: state.webshop.payment.form.payment_type,
    payemntDone: state.webshop.payment.success.payment_done,
    cardPrice: state.webshop.price.data && state.webshop.price.data.card / 100,
  }
}

interface MappedDispatch {
  patchTransaction: (data: string | undefined, bankId: string | undefined) => void
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  patchTransaction: (data: string | undefined, bankId: string | undefined): any =>
    dispatch(patchTransaction(data, bankId)),
})
export default connect<MappedState>(mapStateToProps, mapDispatchToProps)(PaymentMethod)

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .description {
    margin: 20px 0;
    display: block;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
  .spinContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
