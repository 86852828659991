import BaseComponentState from '../Models/BaseComponentState'
import DeepPartial from '../Models/DeepPartial'

export function manipulateData<T>(state: BaseComponentState<T>, partialData): BaseComponentState<T> {
  return { data: { ...state.data, ...partialData } }
}
export function manipulateError<T>(state: BaseComponentState<T>, errors: DeepPartial<T>): BaseComponentState<T> {
  if (Object.keys(errors).length > 0) {
    return { data: state.data, errors }
  } else {
    return { data: state.data, errors: undefined }
  }
}
