import React from 'react'
import styled from 'styled-components'
import lataaSovellus from '../../assets/images/homePage/lataa-sovellus.svg'
import iconAppStore from '../../assets/images/homePage/icon-app-store.png'
import iconGooglePlay from '../../assets/images/homePage/icon-google-play.png'
import { useTranslation } from 'react-i18next'

const AppBanner: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <div className="mod-appBanner__contentWrapper">
        <div className="mod-appBanner__imageWrapper">
          <img className="mod-appBanner__image" src={lataaSovellus} alt="Lataa Museokortti-sovellus" />
        </div>
        <div className="mod-appBanner__textWrapper">
          <h1 className="mod-appBanner__title">{t('downloadTheMuseumCardAppAndWillAlways')}</h1>
          <div className="mod-appBanner__buttonWrapper">
            <a href="https://apps.apple.com/fi/app/museokortti/id1513240463?l=fi">
              <img src={iconAppStore} alt="Lataa App Storesta" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=fi.museot.app&amp;hl=fi">
              <img src={iconGooglePlay} alt="Lataa Google Playsta" />
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AppBanner

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  background-color: white;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  position: relative;
  @media (min-width: 1024px) {
    padding-bottom: 3.75rem;
  }
  @media (min-width: 1024px) {
    padding-top: 0;
  }
  @media (min-width: 1200px) {
    padding-top: 3rem;
    padding-bottom: 3.75rem;
  }
  &:before {
    width: 3000px;
    background-color: white;
    height: 8.75rem;
    transform: rotate(-2deg);
    position: absolute;
    top: -5.625rem;
    left: -50%;
    content: '';
  }

  .mod-appBanner__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
  }
  @media (min-width: 768px) {
    .mod-appBanner__contentWrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: 1.5rem;
      align-items: center;
    }
  }
  .mod-appBanner__title {
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2c253d;
  }
  @media (min-width: 768px) {
    .mod-appBanner__title {
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }
  @media (min-width: 1140px) {
    .mod-appBanner__title {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }
  .mod-appBanner__buttonWrapper {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .mod-appBanner__buttonWrapper {
      justify-content: start;
    }
  }
  .mod-appBanner__buttonWrapper a {
    margin-right: 0.75rem;
  }
  .mod-appBanner__buttonWrapper a:last-child {
    margin-right: 0;
  }
  .mod-appBanner__buttonWrapper a img {
    max-height: 3rem;
  }
  .mod-appBanner__imageWrapper {
    height: 17.5rem;
    width: auto;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  @media (min-width: 768px) {
    .mod-appBanner__imageWrapper {
      grid-column: 8 / span 5;
      grid-row: 1;
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    .mod-appBanner__imageWrapper {
      grid-column: 7 / span 5;
    }
  }
  @media (min-width: 1140px) {
    .mod-appBanner__imageWrapper {
      grid-column: 8 / span 4;
    }
  }
  .mod-appBanner__textWrapper {
    max-width: 26.25rem;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 768px) {
    .mod-appBanner__textWrapper {
      grid-column: 1 / span 6;
      max-width: 100%;
      margin: 0;
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    .mod-appBanner__textWrapper {
      grid-column: 2 / span 5;
    }
  }
  @media (min-width: 1140px) {
    .mod-appBanner__textWrapper {
      grid-column: 3 / span 5;
    }
  }
  .mod-appBanner__image {
    max-width: 100%;
    height: 17.5rem;
    width: auto;
  }
  @media (min-width: 768px) {
    .mod-appBanner__image {
      height: 20rem;
    }
  }
`
