import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Quotes: React.FunctionComponent = () => {
  const [quoteNumber, setQuoteNumber] = useState<number>(1)
  const { t } = useTranslation()

  return (
    <Section>
      <section className="mod-quote">
        <div className="mod-quote__contentWrapper">
          <h2 className="mod-quote__title">{t('commentsFromOurCustomers')}</h2>
          <div className="swiper">
            <div className="swiper-container">
              <div className="swiper-wrapper scroll">
                {quoteNumber === 1 && (
                  <div className="swiper-slide">
                    <blockquote className="mod-quote__quote">{t('anEasyToUseCard')}</blockquote>
                    <p className="mod-quote__author">Milla A.</p>
                  </div>
                )}
                {quoteNumber === 2 && (
                  <div className="swiper-slide">
                    <blockquote className="mod-quote__quote">{t('theMostValuableCardInMyWallet')}</blockquote>
                    <p className="mod-quote__author">Asta J.</p>
                  </div>
                )}
                {quoteNumber === 3 && (
                  <div className="swiper-slide">
                    <blockquote className="mod-quote__quote">{t('theMuseumCardHasMadeItEasy')}</blockquote>
                    <p className="mod-quote__author">Pauli K.</p>
                  </div>
                )}
              </div>
            </div>

            <ul className="atom-pagingDots swiper-pagination">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <li
                className={`atom-pagingDots__dot ${quoteNumber === 1 && 'selected'}`}
                value="0"
                id="0"
                aria-label="slide item 1"
                onClick={() => setQuoteNumber(1)}
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <li
                className={`atom-pagingDots__dot ${quoteNumber === 2 && 'selected'}`}
                value="1"
                id="1"
                aria-label="slide item 2"
                onClick={() => setQuoteNumber(2)}
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <li
                className={`atom-pagingDots__dot ${quoteNumber === 3 && 'selected'}`}
                value="2"
                id="2"
                aria-label="slide item 3"
                onClick={() => setQuoteNumber(3)}
              />
            </ul>
          </div>
        </div>
      </section>
    </Section>
  )
}

export default Quotes

const Section = styled.section`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 540px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  background-color: white;
  padding-top: 2.25rem;
  padding-bottom: 10.25rem;
  position: relative;
  @media (min-width: 1024px) {
    padding-bottom: 10.25rem;
  }
  @media (min-width: 1200px) {
    padding-top: 4.5rem;
    padding-bottom: 10.25rem;
  }

  .atom-pagingDots {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 2.125rem;
    padding: 0.8125rem 1rem;
  }
  .atom-pagingDots__dot {
    height: 0.5rem;
    width: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    background-color: #ffdbd8;
  }
  .atom-pagingDots__dot.-active,
  .atom-pagingDots__dot.selected,
  .atom-pagingDots__dot:hover {
    background-color: #2c253d;
  }
  .atom-pagingDots__dot:focus {
    background-color: #2c253d;
    outline: 0;
    border: 0 none;
  }

  ul,
  li,
  ol {
    list-style-type: none;
  }

  .swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    /* Fix of Webkit flickering */
    z-index: 1;
  }

  .swiper-container-no-flexbox .swiper-slide {
    float: left;
  }

  .swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-fles-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
  }

  .swiper-slide {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  /* a11y */
  .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }

  /* IE10 Windows Phone 8 Fixes */
  .swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
  }

  /* 3D Container */
  .swiper-container-3d {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
  }
  .swiper-container-3d .swiper-wrapper,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
  }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
  }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
  }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
  }

  /* Coverflow */
  .swiper-container-coverflow .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px;
  }

  /* Fade */
  .swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .swiper-container-fade .swiper-slide {
    pointer-events: none;
  }
  .swiper-container-fade .swiper-slide-active {
    pointer-events: auto;
  }

  /* Cube */
  .swiper-container-cube {
    overflow: visible;
  }
  .swiper-container-cube .swiper-slide {
    pointer-events: none;
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
  }
  .swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  .swiper-container-cube .swiper-slide-active,
  .swiper-container-cube .swiper-slide-next,
  .swiper-container-cube .swiper-slide-prev,
  .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
  }
  .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
  }
  .swiper-container-cube.swiper-container-vertical .swiper-cube-shadow {
    z-index: 0;
  }

  /* Arrows */
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    -moz-background-size: 27px 44px;
    -webkit-background-size: 27px 44px;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#007aff'/></svg>");
    left: 10px;
    right: auto;
  }
  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#000000'/></svg>");
  }
  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#ffffff'/></svg>");
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#007aff'/></svg>");
    right: 10px;
    left: auto;
  }
  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#000000'/></svg>");
  }
  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#ffffff'/></svg>");
  }

  /* Pagination Styles */
  .swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  .swiper-pagination-white .swiper-pagination-bullet {
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
  .swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff;
  }
  .swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000;
  }

  .swiper-container-vertical > .swiper-pagination {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0px, -50%, 0);
    -moz-transform: translate3d(0px, -50%, 0);
    -o-transform: translate(0px, -50%);
    -ms-transform: translate3d(0px, -50%, 0);
    transform: translate3d(0px, -50%, 0);
  }
  .swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
    margin: 5px 0;
    display: block;
  }

  .swiper-container-horizontal > .swiper-pagination {
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  .swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
    margin: 0 5px;
  }

  /* Preloader */
  .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -moz-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
  }

  .swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><line id='l' x1='60' x2='60' y1='7' y2='27' stroke='#6c6c6c' stroke-width='11' stroke-linecap='round'/></defs><g><use xlink:href='#l' opacity='.27'/><use xlink:href='#l' opacity='.27' transform='rotate(30 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(60 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(90 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(120 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(150 60,60)'/><use xlink:href='#l' opacity='.37' transform='rotate(180 60,60)'/><use xlink:href='#l' opacity='.46' transform='rotate(210 60,60)'/><use xlink:href='#l' opacity='.56' transform='rotate(240 60,60)'/><use xlink:href='#l' opacity='.66' transform='rotate(270 60,60)'/><use xlink:href='#l' opacity='.75' transform='rotate(300 60,60)'/><use xlink:href='#l' opacity='.85' transform='rotate(330 60,60)'/></g></svg>");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><line id='l' x1='60' x2='60' y1='7' y2='27' stroke='#fff' stroke-width='11' stroke-linecap='round'/></defs><g><use xlink:href='#l' opacity='.27'/><use xlink:href='#l' opacity='.27' transform='rotate(30 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(60 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(90 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(120 60,60)'/><use xlink:href='#l' opacity='.27' transform='rotate(150 60,60)'/><use xlink:href='#l' opacity='.37' transform='rotate(180 60,60)'/><use xlink:href='#l' opacity='.46' transform='rotate(210 60,60)'/><use xlink:href='#l' opacity='.56' transform='rotate(240 60,60)'/><use xlink:href='#l' opacity='.66' transform='rotate(270 60,60)'/><use xlink:href='#l' opacity='.75' transform='rotate(300 60,60)'/><use xlink:href='#l' opacity='.85' transform='rotate(330 60,60)'/></g></svg>");
  }

  @-webkit-keyframes swiper-preloader-spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes swiper-preloader-spin {
    100% {
      transform: rotate(360deg);
    }
  }
  /* Scrollbar */
  .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
  }
  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
  }
  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
  }

  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  .mod-quote::before {
    width: 3000px;
    background-color: white;
    height: 8.75rem;
    transform: rotate(-2deg);
    position: absolute;
    top: -5.625rem;
    left: -50%;
    content: '';
  }
  .mod-quote__contentWrapper {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
    /*@include breakpoint-min-768 {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: rem(24px);
  }*/
  }
  .mod-quote__title {
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.075rem;
    color: #2c253d;
    text-transform: uppercase;
    margin-bottom: 4.5rem;
    text-align: center;
  }
  .mod-quote__quote {
    padding: 2.5rem 2.75rem;
    background-color: #e7f4fe;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #2c253d;
    border-radius: 1.25rem;
    position: relative;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    .mod-quote__quote {
      font-size: 1.625rem;
      line-height: 2rem;
      padding: 2.75rem 3.75rem;
      max-width: 25rem;
    }
  }
  .mod-quote__quote:before {
    height: 2.5rem;
    width: 2.5rem;
    content: '';
    background-color: #e7f4fe;
    transform: rotate(45deg);
    transform-origin: center;
    position: absolute;
    bottom: -1rem;
    left: calc(50% - 20px);
  }
  .mod-quote__quote:after {
    height: 2.5rem;
    width: 2.5rem;
    content: '“';
    font-size: 3.5rem;
    line-height: 4rem;
    position: absolute;
    top: -1.625rem;
    left: 2.5rem;
    color: #2c253d;
  }
  @media (min-width: 1024px) {
    .mod-quote__quote:after {
      left: 3.75rem;
    }
  }
  .mod-quote__author {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0075rem;
    color: #2c253d;
    margin-top: 3rem;
    text-align: center;
  }
  .mod-quote .atom-pagingDots {
    margin-top: 3rem;
  }
  @media (min-width: 768px) {
    .mod-quote .atom-pagingDots {
      grid-column: 2 / span 10;
    }
  }
  .mod-quote .swiper .btn-wrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    background-color: white;
    top: 0;
    right: 10%;
    width: 132px;
    height: 56px;
    padding: 0;
    box-sizing: content-box;
    transform: translateY(-50%);
  }
  @media (min-width: 768px) {
    .mod-quote .swiper .btn-wrapper {
      padding: 0 32px;
    }
  }
  .mod-quote .swiper .swiper-container {
    position: relative;
    display: flex;
    padding-top: 1.5rem;
  }
  .mod-quote .swiper .swiper-slide {
    width: 100%;
  }
  @media (min-width: 1024px) {
    .mod-quote .swiper .swiper-slide {
      width: 100%;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
  .mod-quote .swiper .swiper-pagination {
    position: relative;
    margin-top: 42px;
  }
  @media (min-width: 768px) {
    .mod-quote .swiper .swiper-pagination {
      padding-top: 64px;
    }
  }
  .mod-quote .swiper .swiper-pagination .swiper-pagination-bullet {
    display: inline-block;
    background: none;
    padding: 10px;
    width: auto;
    height: auto;
  }
  @media (min-width: 640px) {
    .mod-quote .swiper .swiper-pagination .swiper-pagination-bullet {
      padding: 6px;
    }
  }
  .mod-quote .swiper .swiper-pagination .swiper-pagination-bullet:before {
    content: '';
    display: block;
    background: none;
    border: 2px solid #2c253d;
    border-radius: 100%;
    width: 13px;
    height: 13px;
  }
  .mod-quote .swiper .swiper-pagination .swiper-pagination-bullet-active:before {
    background-color: #2c253d;
  }
`
