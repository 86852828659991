/* eslint @typescript-eslint/camelcase: 0 */

import TagManager from 'react-gtm-module'

const gtmEvent = (eventName, tagManagerArgs): any => {
  const eventTagManager = {
    dataLayer: {
      event: eventName,
    },
    dataLayerName: 'dataLayer',
  }
  TagManager.dataLayer(tagManagerArgs)
  TagManager.dataLayer(eventTagManager)
}

export const viewItemList = (cardPrice, giftPrice): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: cardPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_list_name: 'Verkkokauppa',
        item_list_id: '',
        index: 1,
        quantity: '1',
      },
      {
        item_name: 'Lahjaksi Museokortti',
        item_id: '',
        price: giftPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta lahjaksi',
        item_category2: 'cardGift',
        item_list_name: 'Verkkokauppa',
        item_list_id: '',
        index: 2,
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('view_item_list', tagManagerArgs)
}

export const viewItem = (cardPrice, renewalAfterExpiry, giftPrice): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: cardPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
      },
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: renewalAfterExpiry,
        item_brand: 'Museokortti',
        item_category: 'Voimassaolon jatkaminen',
        item_category2: 'cardRenewed',
        item_variant: 'Jatkan Museokorttini voimassaoloa',
        quantity: '1',
      },
      {
        item_name: 'Lahjaksi Museokortti',
        item_id: '',
        price: giftPrice,
        item_brand: 'Museokortti',
        item_category: 'Osta lahjaksi',
        item_category2: 'cardGift',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_variant: 'Lahjaksi Museokortti',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('view_item', tagManagerArgs)
}

export const addToCardTag = (type: string, price): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: type === 'gift' ? 'cardGift' : 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('add_to_cart', tagManagerArgs)
}

export const beginCheckout = (price): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('begin_checkout', tagManagerArgs)
}

export const addPaymentInfo = (paymentType, price): void => {
  const tagManagerArgs = {
    dataLayer: [
      { payment_type: paymentType },
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('add_payment_info', tagManagerArgs)
}

export const purchase = (price, transactionId, coupon): void => {
  const tagManagerArgs = {
    dataLayer: [
      {
        currency: 'EUR',
        value: price,
        tax: 0.0,
        shipping: 0.0,
        affiliation: 'osta.museokortti.fi',
        transaction_id: transactionId,
        coupon: coupon,
      },
      {
        item_name: 'Osta Museokortti',
        item_id: '',
        price: price,
        item_brand: 'Museokortti',
        item_category: 'Osta uusi Museokortti',
        item_category2: 'cardNew',
        item_variant: 'Museokortti itselleni',
        quantity: '1',
        item_coupon: coupon,
      },
    ],
    dataLayerName: 'dataLayer',
  }
  gtmEvent('purchase', tagManagerArgs)
}
