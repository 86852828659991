import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import webshop, { State as WebshopState, initialState as webshopInitialState } from './webshop'
import { ENV } from '../constants/env'

export interface RootState {
  webshop: WebshopState
}

export const rootInitialState: RootState = {
  webshop: webshopInitialState,
}

const rootReducer = combineReducers({
  webshop,
})

const store =
  ENV === 'development'
    ? createStore<RootState, any, any, any>(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
    : createStore<RootState, any, any, any>(rootReducer, applyMiddleware(thunk))

export default store
