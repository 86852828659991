import React from 'react'
import styled from 'styled-components'
import colors from '../../../../constants/colors'

interface Props {
  imageSource: string
  onClick?: () => void
  id: string
}
const PlusMinusIcon: React.FunctionComponent<Props> = ({ imageSource, onClick, id }: Props) => {
  return (
    <Container onClick={onClick} id={id}>
      <img src={imageSource} alt="plus" className="icon" />
    </Container>
  )
}

const Container = styled.button`
  background-color: ${colors.primary};
  border: 0;
  border-radius: 10px;
  &:focus {
    outline-color: ${colors.white};
    outline: 0;
  }
  cursor: pointer;
  .icon {
    height: 8px;
    width: 8px;
  }
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}
`

export default PlusMinusIcon
