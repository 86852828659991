import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../../../UiKit/Text'

interface Props {
  children?: React.ReactNode
  title: string
  description?: string
}
const Question: React.FunctionComponent<Props> = ({ children, title, description }) => {
  return (
    <Container>
      <Text type={TextType.BIG_BOLD} className="text-display title-margin">
        {title}
      </Text>
      <Text className="text-display">{description}</Text>
      {children}
    </Container>
  )
}

const Container = styled.div`
  text-align: left;
  .text-display {
    display: block;
  }
  .title-margin {
    margin-bottom: 10px;
  }
`

export default Question
