import Types from './actions/types'
import { ActionTypes } from './actions/creators'
import PaymentResponse from '../../../../Models/PaymentResponse'

export const initialState: State = {
  is_fetching: false,
  payment_type: 'personal',
  error: null,
}

export default (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case Types.POST_FORM_REQUEST:
      return { ...state, is_fetching: true, error: null }
    case Types.POST_FORM_SUCCESS:
      return { ...state, data: action.data, is_fetching: false, error: null }
    case Types.POST_FORM_FAILURE:
      return { ...state, error: action.error, is_fetching: false }
    case Types.SET_PAYMENT_TYPE:
      return { ...state, is_fetching: false, payment_type: action.value, error: null }
    default:
      return state
  }
}

export interface State {
  data?: PaymentResponse
  is_fetching: boolean
  error?: any
  payment_type: string
}
