import Types from './types'
import UpdateTransactionRequest from '../../../../../Models/UpdateTransactionRequest'

export type ActionTypes =
  | { type: Types.UPDATE_TRANSACTION_REQUEST; data: UpdateTransactionRequest }
  | { type: Types.UPDATE_TRANSACTION_SUCCESS }
  | { type: Types.UPDATE_TRANSACTION_FAILURE; error: any }
  | { type: Types.UPDATE_TRANSACTION_PAYMENT_FAILURE; error: string }
  | { type: Types.CLEAR_ERROR_MESSAGE }

export const createUpdateTransactionRequestAction = (data: UpdateTransactionRequest): ActionTypes => ({
  type: Types.UPDATE_TRANSACTION_REQUEST,
  data,
})
export const createUpdateTransactionSuccessAction = (): ActionTypes => ({
  type: Types.UPDATE_TRANSACTION_SUCCESS,
})
export const createUpdateTransactionFailureAction = (error: any): ActionTypes => ({
  type: Types.UPDATE_TRANSACTION_FAILURE,
  error,
})
export const createUpdateTransactionPaymentFailureAction = (error: string): ActionTypes => ({
  type: Types.UPDATE_TRANSACTION_PAYMENT_FAILURE,
  error,
})
export const createClearPaymentErrorMessage = (): ActionTypes => ({
  type: Types.CLEAR_ERROR_MESSAGE,
})
