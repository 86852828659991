import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import ReceiptAPI from '../../../../../api/ReceiptAPI'

const utilLogger = (data: any): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>
): Promise<void> => {
  try {
    const receiptAPI = new ReceiptAPI()
    await receiptAPI.utilLogger(data)
  } catch (error) {
    //handle error
  }
}

export default utilLogger
