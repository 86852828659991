import React from 'react'
import styled from 'styled-components'
import SelectBankBlock from './SelectBankBlock'
import bankImage from '../../assets/images/bankLogos/bank-1.png'
import bank2Image from '../../assets/images/bankLogos/bank-2.png'
import bank3Image from '../../assets/images/bankLogos/bank-3.png'
import bank50Image from '../../assets/images/bankLogos/bank-50.png'
import bank51Image from '../../assets/images/bankLogos/bank-51.png'
import bank52Image from '../../assets/images/bankLogos/bank-52.png'
import bank5Image from '../../assets/images/bankLogos/bank-5.png'
import bank6Image from '../../assets/images/bankLogos/bank-6.png'
import bank10Image from '../../assets/images/bankLogos/bank-10.png'
import bank61Image from '../../assets/images/bankLogos/bank-61.png'
import bank58Image from '../../assets/images/bankLogos/bank-58.png'
import collectorb2c from '../../assets/images/bankLogos/walley-lasku-ja-osamaksu.png'

interface Props {
  type?: string
  onChange: (bankId: string) => void
}

const Paytrail: React.FunctionComponent<Props> = ({ onChange, type }) => {
  return (
    <Container>
      <div className="methods-row">
        <SelectBankBlock id="nordea" imageSource={bankImage} toggleIsChecked={onChange.bind(undefined, 'nordea')} />
        <SelectBankBlock
          id="osuuspankki"
          imageSource={bank2Image}
          toggleIsChecked={onChange.bind(undefined, 'osuuspankki')}
        />
        <SelectBankBlock id="danske" imageSource={bank3Image} toggleIsChecked={onChange.bind(undefined, 'danske')} />
      </div>
      <div className="methods-row">
        <SelectBankBlock id="aktia" imageSource={bank50Image} toggleIsChecked={onChange.bind(undefined, 'aktia')} />
        <SelectBankBlock id="pop" imageSource={bank51Image} toggleIsChecked={onChange.bind(undefined, 'pop')} />
        <SelectBankBlock
          id="saastopankki"
          imageSource={bank52Image}
          toggleIsChecked={onChange.bind(undefined, 'saastopankki')}
        />
      </div>
      <div className="methods-row">
        <SelectBankBlock
          id="alandsbanken"
          imageSource={bank5Image}
          toggleIsChecked={onChange.bind(undefined, 'alandsbanken')}
        />
        <SelectBankBlock
          id="handelsbanken"
          imageSource={bank6Image}
          toggleIsChecked={onChange.bind(undefined, 'handelsbanken')}
        />
        <SelectBankBlock id="spankki" imageSource={bank10Image} toggleIsChecked={onChange.bind(undefined, 'spankki')} />
      </div>
      <div className="methods-row">
        <SelectBankBlock id="omasp" imageSource={bank61Image} toggleIsChecked={onChange.bind(undefined, 'omasp')} />
        <SelectBankBlock
          id="mobilepay"
          imageSource={bank58Image}
          toggleIsChecked={onChange.bind(undefined, 'mobilepay')}
        />
        <SelectBankBlock
          id="collectorb2c"
          imageSource={collectorb2c}
          toggleIsChecked={onChange.bind(undefined, 'collectorb2c')}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .methods-row {
    justify-content: space-between;
    display: flex;
    margin: 12px 0;
  }
  .false-div {
    flex: 1;
    margin-right: 2rem;
  }
`

export default Paytrail
